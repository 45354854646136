import React, {useContext} from "react";
import Box from "@mui/material/Box";
import {Crop169, CropPortrait, CropSquare, HourglassEmpty} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {Card, CardContent, ClickAwayListener} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import {ClipSize16And9RatioId, ClipSize1And1RatioId, ClipSize9And16RatioId} from "../../utils/utils";
import {LibrettoLiveblocksContext} from "./LibrettoLiveblocksContext";
import {createSetClipSize} from "./Liveblocks";

const ClipSizeCard = ({handleClose, setClipSize, clipSize, topPosition, leftPosition, guestEditor}) => {

    const liveblocksContext = useContext(LibrettoLiveblocksContext);

    const setClipSizeMutation = createSetClipSize(liveblocksContext);

    const effectiveTopPosition = guestEditor ? topPosition + 33 : topPosition + 43;

    const effectiveLeftPosition = leftPosition - 50;

    const handle16And9RatioClick = () => {
        setClipSizeMutation(ClipSize16And9RatioId);
        setClipSize(ClipSize16And9RatioId);
        handleClose();
    }

    const handle9And16RatioClick = () => {
        setClipSizeMutation(ClipSize9And16RatioId);
        setClipSize(ClipSize9And16RatioId);
        handleClose();
    }

    const handle1And1RatioClick = () => {
        setClipSizeMutation(ClipSize1And1RatioId);
        setClipSize(ClipSize1And1RatioId);
        handleClose();
    }

    const ClipSizeSelectBox = ({inputClipSize, ratioText, clipSizeIcon, surfacesText, onClickHandler}) => {
        return (
            <Box onClick={onClickHandler} sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingX: 1,
                marginBottom: 2,
                cursor: 'pointer',
                transition: 'background-color 0.3s, border 0.3s',
                border: '1px solid transparent',
                borderRadius: '8px',
                '&:hover': {
                    border: '1px solid',
                    borderColor: 'white',
                    borderRadius: '8px',
                },
            }}>
                <Box flexDirection="row" display="flex" justify gap={1}>
                    {clipSizeIcon}
                    <Box flexDirection="column">
                        <Typography variant="subtitle2" color="white" fontSize="1.0rem" fontWeight={800}>{ratioText}</Typography>
                        <Typography variant="subtitle2" color="text.secondary" fontWeight={800}>{surfacesText}</Typography>
                    </Box>
                </Box>
                {clipSize === inputClipSize && <CheckIcon sx={{fontSize: '1.5rem', color: 'white'}}/>}
            </Box>
        )
    }

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Card
                elevation={5}
                style={{
                    cursor: 'pointer', position: 'absolute',
                    top: effectiveTopPosition,
                    left: effectiveLeftPosition,
                    width: 275,
                    backgroundColor: 'rgba(0, 0, 0, 0.95)', // Dark background
                    borderRadius: '16px', // Rounded corners
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)', overflow: 'visible', height: 210, zIndex: 9999
                }}
            >
                <CardContent>
                    <ClipSizeSelectBox inputClipSize={ClipSize16And9RatioId} onClickHandler={handle16And9RatioClick} clipSizeIcon={<Crop169 color="primary"/>} ratioText="16:9" surfacesText="YouTube, Spotify"/>
                    <ClipSizeSelectBox inputClipSize={ClipSize9And16RatioId} onClickHandler={handle9And16RatioClick} clipSizeIcon={<CropPortrait color="primary"/>}  ratioText="9:16" surfacesText="Shorts, Reels, TikToks"/>
                    <ClipSizeSelectBox inputClipSize={ClipSize1And1RatioId} onClickHandler={handle1And1RatioClick}  clipSizeIcon={<CropSquare color="primary"/>} ratioText="1:1" surfacesText="LinkedIn, X, Instagram"/>
                </CardContent>
            </Card>
        </ClickAwayListener>
    )
}

export default ClipSizeCard;
