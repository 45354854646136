import {$createParagraphNode, $createTextNode, $getRoot} from "lexical";
import {$createSoundNode} from "./SoundNode";
import {loadTranscriptForTrack, RefreshTokenAndRetry} from "../../utils/utils";

export const ParseTranscriptWords = (transcriptJson) => {
    const wordsArray = transcriptJson.results?.channels?.[0]?.alternatives?.[0]?.words ?? [];

    if (!wordsArray) {
        return [];
    }

    const wordsForTranscript = [];
    wordsArray.forEach((word) => {
        const librettoWord = {
            start: word.start,
            end: word.end,
            word: word.word,
            speaker: word.speaker ? `Speaker ${word.speaker}` : 'Speaker 0',
            speakerId: word.speaker ? word.speaker : 0,
        };
        wordsForTranscript.push(librettoWord);
    });

    wordsForTranscript.sort((a, b) => a.start - b.start);

    return wordsForTranscript;
}

const dotsForDuration = (duration) => {
    // one dot for durations under 1 second
    // two dots for durations greater than 1 second
    return duration < 1 ? '\u00B7' : '\u00B7\u00B7';
}

const zeroIfNull = (lastEndTime) => {
    return lastEndTime === null ? 0 : lastEndTime;
}

const FillerWordsList = ["uh", "um", "mhmm", "mm-mm", "uh-uh", "uh-huh", "nuh-uh"]

export const IsFillerWordInTranscript = (text) => {
    return FillerWordsList.includes(text.toLowerCase());
}

export const OptimizeFillerWordRemovalTrim = (trim) => {
    if (trim.endTime - trim.startTime < 0.3) {
        const diff = 0.3 - (trim.endTime - trim.startTime)
        trim.startTime = trim.startTime - diff/2 < 0 ? 0 : trim.startTime - diff/2
        trim.endTime = trim.endTime + diff/2
    }

    return trim;
}

export const AppendWordsToRoot = (allWords, corrections, trims, root, orderedTrackIndex, fillerWordRemovalTrims) => {
    if (allWords.length === 0) {
        return;
    }

    let para = null
    let lastSource = null
    let lastEndTime = null


    let index = 0
    let speakerIndex = allWords.length + 5

    for (const word of allWords) {
        if (lastSource !== word.speaker) {
            const speakerNameParagraph = $createParagraphNode()
            root.append(speakerNameParagraph)


            const speakerNameTextNode = $createSoundNode(`${word.speaker}: `, {source: word.speaker, start: word.start, end: word.start, isSilence: false, isSpeakerNode: true, transcriptIndex: speakerIndex, originalText: undefined, orderedTrackIndex: orderedTrackIndex});
            const speakerIndexStr = String(speakerIndex);
            if (speakerIndexStr in corrections) {
                const correctedText = corrections[speakerIndexStr].correctedText;
                speakerNameTextNode.setTextContent(correctedText);
            }
            speakerIndex++;
            speakerNameTextNode.toggleFormat('bold');
            speakerNameParagraph.append(speakerNameTextNode);


            para = $createParagraphNode()
            root.append(para)
            lastSource = word.speaker
        }

        if (zeroIfNull(lastEndTime) + 0.7 < word.start) {
            const dots = dotsForDuration(word.start - zeroIfNull(lastEndTime));
            const soundNode = $createSoundNode(dots, {source: word.speaker, start: zeroIfNull(lastEndTime), end: word.start, isSilence: true, isSpeakerNode: false, transcriptIndex: -1, orderedTrackIndex: orderedTrackIndex});
            soundNode.toggleFormat('bold');
            para.append(soundNode);
            para.append($createTextNode(' '))


            if (zeroIfNull(lastEndTime) + 2 < word.start) {
                para = $createParagraphNode()
                root.append(para)


                para.append($createTextNode(''))
            }
        }

        lastEndTime = word.end


        let soundNodeWord = word.word;
        const strIndex = String(index);
        if (strIndex in corrections) {
            const correctedText = corrections[strIndex].correctedText;
            soundNodeWord = correctedText;
        }

        const text = $createSoundNode(soundNodeWord, {source: word.speaker, start: word.start, end: word.end, isSilence: false, isSpeakerNode: false, transcriptIndex: index, originalText: word.word, orderedTrackIndex: orderedTrackIndex});


        for (let trim of trims) {
            if (word.start >= trim.startTime && word.end <= trim.endTime) {
                text.setDeleted();
            }
        }

        for (let trim of fillerWordRemovalTrims) {
            if (word.start >= trim.startTime && word.end <= trim.endTime) {
                text.setRemovedAsFillerWord();
            }
        }

        para.append(text)

        if (index !== allWords.length) {
            para.append($createTextNode(' '))
        }
        index++
    }
}

export const CreateEditorState = (corrections, trims, transcripts, orderOfTracks, fillerWordRemovalTrims) => {

    if (!transcripts || !orderOfTracks || transcripts.size === 0 || orderOfTracks.length === 0) {
        return () => {
        }
    }

    const arrayOfAllWords = []
    const arrayOfCorrections = []
    const arrayOfTrims = []
    let index = 0
    for (const trackId of orderOfTracks) {
        const transcriptJson = transcripts.get(trackId);
        const allWords = transcriptJson === undefined || transcriptJson === null ? []  : ParseTranscriptWords(transcriptJson);
        if (allWords.length === 0) {
            continue;
        }
        allWords.sort((a, b) => a.start - b.start);
        arrayOfAllWords.push(allWords);

        const strIndex = String(index);

        const correctionsForThisTrack = corrections.has(strIndex) && corrections.get(strIndex) ? corrections.get(strIndex).corrections : [];
        arrayOfCorrections.push(correctionsForThisTrack);

        const trimsForThisTrack = trims.get(strIndex) ? trims.get(strIndex).trims : [];
        arrayOfTrims.push(trimsForThisTrack);
        index++;
    }

    // Lexical expects a function to call
    return () => {
        const root = $getRoot()
        root.clear();
        let processingIndex = 0;
        for (const allWords of arrayOfAllWords) {
            AppendWordsToRoot(allWords, arrayOfCorrections[processingIndex], arrayOfTrims[processingIndex], root, processingIndex, fillerWordRemovalTrims);
            processingIndex++;
        }
    }
}

export const GetUtteranceBasedWebVTTUrl = (transcriptJson, corrections) => {

    const results = transcriptJson ? transcriptJson.results : null;

    const utteranceCorrectionMap = new Map();
    for (const entry of Object.entries(corrections)) {
        utteranceCorrectionMap.set(parseInt(entry[0], 10), entry[1].correctedText);
    }

    if (!results || !results.utterances) {
        throw new Error("This function requires a transcript that was generated with the utterances feature.");
    }

    const captionStyles = {
        color: 'white',
        'font-size': '28px',
        'font-family': 'Arial, sans-serif',
        'background-color': 'rgba(0, 0, 0, 0.8)',
        'text-shadow': '2px 2px 2px black',
    };

    let vtt = "WEBVTT\n\n";

    // Add CSS styles to the VTT file
    vtt += "STYLE\n::cue { ";
    for (const [property, value] of Object.entries(captionStyles)) {
        vtt += `${property}: ${value}; `;
    }
    vtt += "}\n\n";

    let correctionIndex = 0;

    results.utterances.forEach((utterance, index) => {
        const start = SecondsToTimestamp(utterance.start);
        const end = SecondsToTimestamp(utterance.end);

        // Split the transcript into an array of words (separated by spaces)
        const transcriptWords = utterance.transcript.split(' ');
        for (let i = 0; i < transcriptWords.length; i++) {
            const correctedWord = utteranceCorrectionMap.get(correctionIndex);
            if (correctedWord) {
                transcriptWords[i] = correctedWord;
            }
            correctionIndex++;
        }
        const revisedTranscript = transcriptWords.join(' ');

        vtt += `${index + 1}\n${start} --> ${end}\n${revisedTranscript}\n\n`;
    });

    const vttBlob = new Blob([vtt], {type: 'text/vtt'});
    return URL.createObjectURL(vttBlob);
}

export const DownloadWebVttForTrack = async (trackId, title, fetchContext, authContext) => {

    const transcriptJson = await loadTranscriptForTrack({trackId, authContext, fetchContext});

    if (!transcriptJson || !transcriptJson.results || !transcriptJson.results.utterances) {
        console.log("Transcript is empty")
        return null;
    }

    const utterances = transcriptJson.results.utterances;

    let vtt = "WEBVTT\n\n";
    utterances.forEach((utterance, index) => {
        const start = SecondsToTimestamp(utterance.start);
        const end = SecondsToTimestamp(utterance.end);
        vtt += `${index + 1}\n${start} --> ${end}\n${utterance.transcript}\n\n`;
    });

    console.log("Doing download")
    const vttBlob = new Blob([vtt], {type: 'text/vtt'});
    const a = document.createElement('a');
    const url = URL.createObjectURL(vttBlob);
    a.href = url;
    a.download = `${title}-transcript.vtt`;
    a.click();
    URL.revokeObjectURL(url);
    return null;
}


export const GetWebVTTUrl = (transcriptJson, corrections) => {
    const allWords = transcriptJson === undefined || transcriptJson === null ? [] : ParseTranscriptWords(transcriptJson);

    const wordCorrectionMap = new Map();
    for (const entry of Object.entries(corrections)) {
        wordCorrectionMap.set(parseInt(entry[0], 10), entry[1].correctedText);
    }

    const captionStyles = {
        color: 'white',
        'font-size': '28px',
        'font-family': 'Arial, sans-serif',
        'background-color': 'rgba(0, 0, 0, 0.8)',
        'text-shadow': '2px 2px 2px black',
    };

    let vtt = "WEBVTT\n\n";

    // Add CSS styles to the VTT file
    vtt += "STYLE\n::cue { ";
    for (const [property, value] of Object.entries(captionStyles)) {
        vtt += `${property}: ${value}; `;
    }
    vtt += "}\n\n";

    let captionIndex = 1;
    let captionText = "";
    let captionStart = null;
    let captionEnd = null;
    let correctionIndex = 0;

    allWords.forEach((word, index) => {
        const correctedWord = wordCorrectionMap.get(correctionIndex);
        const wordText = correctedWord ? correctedWord : word.word;
        correctionIndex++;

        if (!captionStart) {
            captionStart = word.start;
        }

        if (captionText.length + wordText.length + 1 <= 37) {
            captionText += (captionText.length > 0 ? " " : "") + wordText;
            captionEnd = word.end;
        } else {
            vtt += `${captionIndex}\n${SecondsToTimestamp(captionStart)} --> ${SecondsToTimestamp(captionEnd)}\n${captionText}\n\n`;
            captionIndex++;
            captionText = wordText;
            captionStart = word.start;
            captionEnd = word.end;
        }

        if (captionText.length > 0 && captionText.length + wordText.length + 1 > 37 && captionText.split(" ").length > 1) {
            vtt += `${captionIndex}\n${SecondsToTimestamp(captionStart)} --> ${SecondsToTimestamp(captionEnd)}\n${captionText}\n\n`;
            captionIndex++;
            captionText = "";
            captionStart = null;
            captionEnd = null;
        }
    });

    if (captionText.length > 0) {
        vtt += `${captionIndex}\n${SecondsToTimestamp(captionStart)} --> ${SecondsToTimestamp(captionEnd)}\n${captionText}\n\n`;
    }

    const vttBlob = new Blob([vtt], { type: 'text/vtt' });
    return URL.createObjectURL(vttBlob);
};


const SecondsToTimestamp = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const remainingSeconds = seconds - hours * 3600 - minutes * 60;
    return `${PadZero(hours)}:${PadZero(minutes)}:${PadZero(remainingSeconds, true)}`;
}

const PadZero = (num, isSeconds = false) => {
    if (isSeconds) {
        return num.toFixed(3).padStart(6, '0');
    } else {
        return num.toString().padStart(2, '0');
    }
}
