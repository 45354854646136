import React, { createContext } from 'react';
import axios from 'axios';

const FetchContext = createContext();
const { Provider } = FetchContext;

const isProduction = true;

const LOCAL_BASE_URL = "http://localhost:8088";

const LOCAL_WEBSOCKET_BASE = "ws://localhost:8088";

const PROD_BASE_URL = "https://api.neuvideo.io";

const LOCAL_CLIENT_URL = "http://localhost:3000";

const PROD_CLIENT_URL = "https://libretto.fm"

const CLIENT_URL = isProduction ? PROD_CLIENT_URL : LOCAL_CLIENT_URL;

const PROD_WEBSOCKET_BASE = "wss://api.neuvideo.io";

const BASE_URL = isProduction ? PROD_BASE_URL : LOCAL_BASE_URL;

const BASE_WEBSOCKET_URL = isProduction ? PROD_WEBSOCKET_BASE : LOCAL_WEBSOCKET_BASE;

const FetchProvider = ({ children }) => {
    const authAxios = axios.create({
        baseURL: BASE_URL,
    });

    const getBaseURL = () => {
        return BASE_URL;
    };

    const getBaseWebsocketURL = () => {
        return BASE_WEBSOCKET_URL;
    }

    return (
        <Provider
            value={{
                authAxios,
                getBaseURL,
                getBaseWebsocketURL,
            }}
        >
            {children}
        </Provider>
    );
};

export { FetchContext, FetchProvider, BASE_URL, BASE_WEBSOCKET_URL, CLIENT_URL };
