import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {Avatar, ListItemIcon} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import Link from "@mui/material/Link";
import React from "react";
import IconButton from "@mui/material/IconButton";

const AccountIconButton = ({ photoUrl, handleClick }) => {
    return (
        <IconButton onClick={handleClick} sx={{ fontSize: '1.5rem', position: 'relative' }}>
            <Avatar
                src={photoUrl}
                sx={{
                    width: '2rem',
                    height: '2rem',
                    backgroundColor: 'primary.main',
                    position: 'relative',
                    fontWeight: 600,
                }}
            />
        </IconButton>
    );
}

const AvatarMenu = ({ handleClick, handleLogout, anchorEl, open, handleClose, email, photoUrl, navigateToSettings }) => {
    return (
        <>
            <Tooltip title="Menu">
                <AccountIconButton photoUrl={photoUrl} handleClick={handleClick} />
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 3,
                    sx: {
                        overflow: 'visible',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                        borderRadius: '10px',
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem>
                    <Typography fontWeight={600}>
                        {email}
                    </Typography>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={() => navigateToSettings()}>
                    <ListItemIcon onClick={() => navigateToSettings()}>
                        <SettingsIcon />
                    </ListItemIcon>
                    <Typography color="text.primary">Settings</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon onClick={handleLogout}>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <Link
                        underline="none"
                        onClick={handleLogout}
                        color="text.primary"
                    >
                        Logout
                    </Link>
                </MenuItem>
            </Menu>
        </>
    )
}

export default AvatarMenu;
