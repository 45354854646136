/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import {alpha, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from "@mui/material/Avatar";
import NoiseReductionIcon from "../../../assets/NoiseReductionIcon";
import EqualizationIcon from "../../../assets/EqualizationIcon";
import DynamicsProcessingIcon from "../../../assets/DynamicsIcon";
import LoudnessCorrectionIcon from "../../../assets/LoudnessCorrectionIcon";

const items = ({isMd}) => [
    {
        title: 'Loudness Correction',
        icon: (
            <LoudnessCorrectionIcon/>
        ),
    },
    {
        title: 'Noise Reduction',
        icon: (
            <NoiseReductionIcon/>
        ),
    },
    {
        title: 'Dynamics Processing',
        icon: (
            <DynamicsProcessingIcon/>
        ),
    },
    {
        title: isMd ? 'Equalization' : 'Intelligent Equalization',
        icon: (
            <EqualizationIcon/>
        ),
    },
];

export const NewAudioEnhanceFeature = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
        if (viewPortEntered) {
            return;
        }

        setViewPortEntered(isVisible);
    };

    return (
        <Box>
            <Grid container spacing={isMd ? 4 : 0} direction={isMd ? 'row' : 'column-reverse'} alignItems="center">
                <Grid
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={7}
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap', // Ensure boxes can wrap as needed
                        borderRadius: '25px',
                        paddingBottom: 4,
                        paddingTop: 4,
                        rowGap: isMd ? 4 : 2,
                    }}
                >
                    {items(isMd).map((item, i) => (
                        <Grid item xs={6} key={i} sx={{ padding: 2 }}>
                            <Box
                                width={1}
                                height={1}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: 3,
                                    borderRadius: '25px',
                                    bgcolor: 'background.paper', // Consider a subtle background color
                                    boxShadow: 3, // Add shadow for depth
                                    transition: 'transform .2s', // Smooth transition for hover effect
                                    '&:hover': {
                                        transform: 'scale(1.05)', // Scale up on hover
                                        bgcolor: '#2B6BFD', // Change background color on hover
                                    },
                                }}
                            >
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        marginBottom: 2,
                                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                                        color: theme.palette.primary.main,
                                    }}
                                >
                                    {item.icon}
                                </Avatar>
                                <Typography
                                    gutterBottom
                                    sx={{ fontWeight: 800, marginBottom: 2, fontSize: "1.25rem" }}
                                    align={'center'}
                                    color={'text.primary'} // Consider changing the color for better contrast
                                >
                                    {item.title}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box marginBottom={4}>
                        <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom color={"#2B6BFD"}>
                            Magic Audio
                        </Typography>
                        <Typography variant={'subtitle1'} component={'p'} fontFamily={'Inter'} color={'text.secondary'} fontSize="1.2rem" marginBottom={4}>
                            Libretto automates labor intensive media processing tasks that would otherwise take hours to complete.
                        </Typography>
                        <Typography variant={'subtitle1'} component={'p'} fontFamily={'Inter'} color={'text.secondary'} fontSize="1.2rem">
                            Speech leveling, noise reduction, dynamic equalization, tone shaping and more at the click of a button.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const AudioEnhanceFeature = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
        if (viewPortEntered) {
            return;
        }

        setViewPortEntered(isVisible);
    };

    return (
        <Box>
            <Grid container spacing={isMd ? 4 : 0} direction={isMd ? 'row' : 'column-reverse'} alignItems="center">
                <Grid
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={7}
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap', // Ensure boxes can wrap as needed
                        borderRadius: '25px',
                        paddingBottom: 4,
                        paddingTop: 4,
                        rowGap: isMd ? 4 : 2,
                    }}
                >
                    {items(isMd).map((item, i) => (
                        <Grid item xs={6} key={i} sx={{ padding: 2 }}>
                            <Box
                                width={1}
                                height={1}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: 3,
                                    borderRadius: '25px',
                                    bgcolor: 'background.paper', // Consider a subtle background color
                                    boxShadow: 3, // Add shadow for depth
                                    transition: 'transform .2s', // Smooth transition for hover effect
                                    '&:hover': {
                                        transform: 'scale(1.05)', // Scale up on hover
                                        bgcolor: 'primary.light', // Change background color on hover
                                    },
                                }}
                            >
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        marginBottom: 2,
                                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                                        color: theme.palette.primary.main,
                                    }}
                                >
                                    {item.icon}
                                </Avatar>
                                <Typography
                                    gutterBottom
                                    sx={{ fontWeight: 800, marginBottom: 2, fontSize: "1.25rem" }}
                                    align={'center'}
                                    color={'text.primary'} // Consider changing the color for better contrast
                                >
                                    {item.title}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box marginBottom={4}>
                        <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom>
                            One-click{' '}
                            <Typography
                                color={'primary'}
                                component={'span'}
                                variant={'inherit'}
                            >
                                audio Post Production
                            </Typography>
                        </Typography>
                            <Typography variant={'subtitle1'} component={'p'} color={'text.secondary'} fontSize="1.2rem" marginBottom={4}>
                                Libretto automates labor intensive media processing tasks that would otherwise take hours to complete.
                            </Typography>
                            <Typography variant={'subtitle1'} component={'p'} color={'text.secondary'} fontSize="1.2rem">
                                Speech leveling, noise reduction, dynamic equalization, tone shaping and more at the click of a button.
                            </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AudioEnhanceFeature;
