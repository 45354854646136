import {
    CreateWebSocket,
    SerializeVideoChunks,
} from "../utils/utils";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {BASE_WEBSOCKET_URL} from "../context/FetchContext";
import {LocalAudioTrack, LocalVideoTrack, RoomEvent} from "livekit-client";
import CustomMediaRecorder from "./MediaRecorder/CustomMediaRecorder";

const WS_DATA_CHANNEL_CAPACITY = 2 * 10 * 1024 * 1024;
const MEDIA_RECORDER_TIME_SLICE_MS = 10000;
const EOS_MARKER = new Uint8Array([69, 79, 83, 45, 65, 85, 68, 73, 79]);

const ConsumeData = async (mediaData, consumeAll, recordingSocket) => {
    if (mediaData.length > 0) {

        if (consumeAll) {
            // console.log("Consuming all data");
        }

        let accumulatedChunks = [];
        let totalSize = 0;

        let sizeLimit = WS_DATA_CHANNEL_CAPACITY;
        if (consumeAll) {
            sizeLimit *= 1000;
        }

        while (mediaData.length > 0 && totalSize < sizeLimit) {
            const chunk = mediaData[0];
            const chunkSize = chunk.size;

            if (totalSize + chunkSize > WS_DATA_CHANNEL_CAPACITY) {
                break;
            }

            mediaData.shift();  // Consider optimizing this operation if audioData is large
            accumulatedChunks.push(chunk);
            totalSize += chunkSize;
        }

        if (accumulatedChunks.length > 0) {
            // console.log("Total size to be sent is ", totalSize)
            const serializedChunks = await SerializeVideoChunks(accumulatedChunks);
            // console.log("Serialized chunks has length ", serializedChunks.length);
            // console.log("Sending data over websocket")
            recordingSocket.send(serializedChunks);
            if (consumeAll) {
                console.log("Sending EOS_MARKER")
                recordingSocket.send(EOS_MARKER);
            }
        }
    } else if (consumeAll) {
        console.log("SENDING EOS_MARKER")
        recordingSocket.send(EOS_MARKER);
    }
};

const HighQualityVideoRecording = forwardRef(function HighQualityVideoRecording({
                                                                                    livekitToken,
                                                                                    room,
                                                                                    localRecordingEnabled,
                                                                                    setRecordingDone,
                                                                                    setLocalRecordingStopped,
                                                                                    roomName,
                                                                                    componentName,
                                                                                    isHDQuality,
                                                                                }, ref) {
    const [isRecording, setIsRecording] = useState(false);
    const videoDataRef = useRef([]);
    const [videoRecordingSocket, setVideoRecordingSocket] = useState(null);
    const recordingStopped = useRef(false);
    const serverReceivedEos = useRef(false);

    // Set up websockets to receive audio and video frames.
    useEffect(() => {
        if (livekitToken === "" || roomName === "" || !localRecordingEnabled) return;
        const videoRecordUrl = `${BASE_WEBSOCKET_URL}/ws/recording/video/${roomName}/${livekitToken}`;
        const videoWs = CreateWebSocket(videoRecordUrl, setVideoRecordingSocket, (event) => {
            if (event.data === "EOS-AUDIO") {
                serverReceivedEos.current = true;
                console.log("Server received EOS_MARKER");
            }
        }, localRecordingEnabled);
        return () => {
            if (videoWs.readyState === WebSocket.OPEN) videoWs.close();
        };
    }, [livekitToken, roomName, localRecordingEnabled]);

    const audioContextRef = useRef(null);
    const mixedDestinationRef = useRef(null);
    const mediaRecorderRef = useRef(null);

    useEffect(() => {
        if (!localRecordingEnabled) {
            return;
        }

        const newAudioContext = new AudioContext({sampleRate: 48000, latencyHint: "playback"});
        const newMixedDestination = newAudioContext.createMediaStreamDestination();
        audioContextRef.current = newAudioContext;
        mixedDestinationRef.current = newMixedDestination;

        return () => {
            if (newAudioContext !== null && newAudioContext.state !== "closed") {
                newAudioContext.close();
            }
        };
    }, [localRecordingEnabled]);

    useEffect(() => {
        if (room === null || audioContextRef.current === null || mixedDestinationRef.current === null || !localRecordingEnabled) {
            return;
        }

        const connectAudioTrack = async (trackPublication) => {
            if (trackPublication.track instanceof LocalAudioTrack) {
                const audioTrack = trackPublication.audioTrack;
                const sourceNode = audioContextRef.current.createMediaStreamSource(new MediaStream([audioTrack.mediaStreamTrack]));
                sourceNode.connect(mixedDestinationRef.current);
            }
        };


        room.on(RoomEvent.LocalTrackPublished, (trackPublication) => {
            connectAudioTrack(trackPublication);
        });
    }, [room, videoDataRef, isHDQuality]);

    const startRecording = async () => {
        if (isRecording) {
            console.log("Start recording called but already recording");
            return;
        }

        if (!localRecordingEnabled) {
            console.log("Local recording not enabled");
            setIsRecording(true);
            return;
        }

        console.log(componentName, ": Start recording called");

        // Setup audio recording
        const mixedDestination = mixedDestinationRef.current;

        const audioTrack = new LocalAudioTrack(mixedDestination.stream.getAudioTracks()[0]);

        let mediaStream;
        if (room.localParticipant.videoTrackPublications.size === 0) {
            mediaStream = new MediaStream([audioTrack.mediaStreamTrack]);
        } else {
            const firstKey = room.localParticipant.videoTrackPublications.keys().next().value;
            const videoTrack = room.localParticipant.videoTrackPublications.get(firstKey).videoTrack;
            mediaStream = new MediaStream([videoTrack.mediaStreamTrack, audioTrack.mediaStreamTrack]);
        }

        const mediaRecorder = new CustomMediaRecorder(mediaStream);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = (event) => {
            videoDataRef.current.push(event.data);
            ConsumeData(videoDataRef.current, false, videoRecordingSocket);
        };

        mediaRecorder.start(MEDIA_RECORDER_TIME_SLICE_MS, isHDQuality).then(() => {
            console.log("Media recorder started");
        });

        const ReplaceVideoTrack = async (trackPublication) => {
            if (trackPublication.track instanceof LocalVideoTrack) {
                const videoStreamTrack = trackPublication.videoTrack.mediaStreamTrack;
                mediaRecorder.replaceVideoTrack(videoStreamTrack);
            }
        }

        room.on(RoomEvent.LocalTrackPublished, ReplaceVideoTrack);
        room.on(RoomEvent.TrackUnmuted, ReplaceVideoTrack);

        setIsRecording(true);
    }

    const stopRecording = () => {
        if (!isRecording) {
            console.log(componentName, ": Stop recording called but not recording");
            return;
        }

        if (!localRecordingEnabled) {
            console.log("Local recording not enabled");
            setIsRecording(false);
            setLocalRecordingStopped(true);
            setTimeout(() => {
                setRecordingDone(true);
            }, 1000);
            return;
        }

        console.log(componentName, ": Stop recording called");

        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }

        setIsRecording(false);
        recordingStopped.current = true;
        setLocalRecordingStopped(true);
        console.log(componentName, ": Local recording stopped");
        setTimeout(() => {
            ConsumeData(videoDataRef.current, true, videoRecordingSocket).then(() => {
                ReliablySendEosMarker(videoRecordingSocket, serverReceivedEos, setRecordingDone);
            });
        }, 2000);
    };

    const SendEosMarker = (videoRecordingSocket, serverReceivedEos, setRecordingDone) => {
        if (serverReceivedEos.current) {
            setRecordingDone(true);
            return;
        }
        if (videoRecordingSocket.readyState === WebSocket.OPEN) {
            videoRecordingSocket.send(EOS_MARKER);
        }
    }

    const ReliablySendEosMarker = (videoRecordingSocket, serverReceivedEos, setRecordingDone) => {
        SendEosMarker(videoRecordingSocket, serverReceivedEos);
        // Retry sending EOS_MARKER every 2 seconds three times
        setTimeout(() => SendEosMarker(videoRecordingSocket, serverReceivedEos, setRecordingDone), 2000);
        setTimeout(() => SendEosMarker(videoRecordingSocket, serverReceivedEos, setRecordingDone), 4000);
        setTimeout(() => SendEosMarker(videoRecordingSocket, serverReceivedEos, setRecordingDone), 6000);
        // If server still hasn't received EOS_MARKER, set recording done
        setTimeout(() => {
            setRecordingDone(true);
        }, 8000);
    }

    const isHighQualityRecordingActive = () => {
        return isRecording;
    }

    useImperativeHandle(ref, () => ({
        startRecording,
        stopRecording,
        isHighQualityRecordingActive
    }), [startRecording, stopRecording]);

    return (
        <div style={{display: "none"}}/>
    )
});

export default HighQualityVideoRecording;
