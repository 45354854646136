import SquareIcon from "@mui/icons-material/Square";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {Button, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {HourglassEmpty} from "@mui/icons-material";

const RecordButton = React.memo(({ recordingInProgress, localRecordingInProgress, startRecording, stopRecording, isButtonDisabled }) => {
    const [recordTime, setRecordTime] = useState(0);
    const timerRef = useRef(null);

    useEffect(() => {
        if (recordingInProgress) {
            timerRef.current = setInterval(() => {
                setRecordTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(timerRef.current);
            setRecordTime(0);
        }

        return () => clearInterval(timerRef.current);
    }, [recordingInProgress]);

    const buttonStyle = {
        backgroundColor: recordingInProgress || localRecordingInProgress ? "#000" : "#FF0000",
        color: "white",
        minWidth: "100px",
        borderRadius: "10px",
        padding: "10px",
    }

    const ButtonIcon = () => {
        const iconStyle = recordingInProgress || localRecordingInProgress
            ? {
                color: '#FF0000',
                animation: 'flashing 1s infinite'
            }
            : {
                color: '#FF0000'
            };


        if (recordingInProgress) {
            return (
                <SquareIcon style={iconStyle} />
            );
        }

        if (localRecordingInProgress) {
            return (
                <HourglassEmpty/>
            );
        }

        return (
            <RadioButtonCheckedIcon/>
        );
    }

    const handleButtonClick = async () => {
        if (isButtonDisabled || (localRecordingInProgress && !recordingInProgress)) return; // Prevents button action if disabled

        if (recordingInProgress) {
            await stopRecording();
        } else {
            await startRecording();
        }
    }

    const formatTime = () => {
        const minutes = Math.floor(recordTime / 60).toString().padStart(2, '0');
        const seconds = (recordTime % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    const ButtonText = () => {
        if (recordingInProgress) {
            return (
                <Typography>
                    {formatTime()}
                </Typography>
            );
        }

        if (localRecordingInProgress) {
            return (
                <Typography>
                    Starting...
                </Typography>
            );
        }

        return (
            <Typography>
                Record
            </Typography>
        );
    }

    return (
        <>
            <style>
                {`
                    @keyframes flashing {
                        0% { opacity: 1; }
                        50% { opacity: 0; }
                        100% { opacity: 1; }
                    }
                `}
            </style>
            {<Button style={buttonStyle} onClick={handleButtonClick} startIcon={<ButtonIcon/>}>
                {ButtonText()}
            </Button>}
        </>);
});

export default RecordButton;
