import React, {useContext, useEffect, useState} from "react";
import {
    Card,
    CardContent,
    ClickAwayListener,
    IconButton,
    Stack,
    Switch,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import TranscriptionLanguageSelectDropdown from "./TranscriptionLanguageDropdown";
import {collection, doc, onSnapshot, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {kRoomStateCollectionName, RoomName} from "../../utils/CollectionConstants";
import {
    GridVideoLayout,
    HighVideoResolution,
    SingleSpeakerVideoLayout,
    SpeakerVideoLayout,
    StandardVideoResolution
} from "../../utils/utils";

const VideoQualitySelectCard = ({selectedVideoQuality, updateVideoResolution}) => {

    return (
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" marginBottom={0}>
            <Card
                onClick={() => updateVideoResolution(StandardVideoResolution)}
                elevation={5}
                style={{
                    cursor: 'pointer',
                    position: 'relative',
                    border: selectedVideoQuality === StandardVideoResolution ? `2px solid white` : '2px solid gray',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
                    overflow: 'visible',
                    minHeight: 80,
                    height: 180,
                    width: 270,
                    backgroundColor: 'rgba(50, 50, 50, 0.3)', // Dark theme color
                    borderRadius: '16px',
                }}
            >
                <CardContent>
                    <Typography variant="body1"
                                marginTop={1} fontWeight={900} color="white">Standard Resolution</Typography>
                    <Typography variant="subtitle2"
                                marginTop={1} fontWeight={500} color="text.secondary">Separate tracks record in 720p.
                        You can export composed edits in higher resolutions up to 4K.</Typography>
                    <Typography variant="caption"
                                marginTop={1} fontWeight={500} color="text.secondary">Fast upload and processing
                        times.</Typography>
                </CardContent>
            </Card>
            <Card
                onClick={() => updateVideoResolution(HighVideoResolution)}
                elevation={5}
                style={{
                    cursor: 'pointer',
                    position: 'relative',
                    border: selectedVideoQuality === HighVideoResolution ? `2px solid white` : '2px solid gray',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
                    overflow: 'visible',
                    minHeight: 80,
                    height: 180,
                    width: 270,
                    backgroundColor: 'rgba(50, 50, 50, 0.3)', // Dark theme color
                    borderRadius: '16px',
                }}
            >
                <CardContent>
                    <Typography variant="body1"
                                marginTop={1} fontWeight={900} color="white">High Resolution</Typography>
                    <Typography variant="subtitle2"
                                marginTop={1} fontWeight={500} color="text.secondary">For cameras that support 1080p and
                        4K HD recording.</Typography>
                    <Typography variant="caption"
                                marginTop={1} fontWeight={500} color="text.secondary">Longer upload and processing
                        times.</Typography>
                </CardContent>
            </Card>
        </Stack>
    )
}

const VideoLayoutSelectCard = ({selectedVideoLayout, updateVideoLayout}) => {

    return (
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" marginBottom={0}>
            <Card
                onClick={() => updateVideoLayout(GridVideoLayout)}
                elevation={5}
                style={{
                    cursor: 'pointer',
                    position: 'relative',
                    border: selectedVideoLayout === GridVideoLayout ? `2px solid white` : '2px solid gray',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
                    overflow: 'visible',
                    minHeight: 80,
                    height: 135,
                    width: 202,
                    backgroundColor: 'rgba(50, 50, 50, 0.3)', // Dark theme color
                    borderRadius: '16px',
                }}
            >
                <CardContent>
                    <Typography variant="body1"
                                marginTop={1} fontWeight={900} color="white">Grid</Typography>
                    <Typography variant="subtitle2"
                                marginTop={1} fontWeight={500} color="text.secondary">Every participant's video is in a grid.</Typography>
                </CardContent>
            </Card>
            <Card
                onClick={() => updateVideoLayout(SpeakerVideoLayout)}
                elevation={5}
                style={{
                    cursor: 'pointer',
                    position: 'relative',
                    border: selectedVideoLayout === SpeakerVideoLayout ? `2px solid white` : '2px solid gray',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
                    overflow: 'visible',
                    minHeight: 80,
                    height: 135,
                    width: 202,
                    backgroundColor: 'rgba(50, 50, 50, 0.3)', // Dark theme color
                    borderRadius: '16px',
                }}
            >
                <CardContent>
                    <Typography variant="body1"
                                marginTop={1} fontWeight={900} color="white">Speaker</Typography>
                    <Typography variant="subtitle2"
                                marginTop={1} fontWeight={500} color="text.secondary">The active speaker is centered.</Typography>
                </CardContent>
            </Card>
            <Card
                onClick={() => updateVideoLayout(SingleSpeakerVideoLayout)}
                elevation={5}
                style={{
                    cursor: 'pointer',
                    position: 'relative',
                    border: selectedVideoLayout === SingleSpeakerVideoLayout ? `2px solid white` : '2px solid gray',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
                    overflow: 'visible',
                    minHeight: 80,
                    height: 135,
                    width: 202,
                    backgroundColor: 'rgba(50, 50, 50, 0.3)', // Dark theme color
                    borderRadius: '16px',
                }}
            >
                <CardContent>
                    <Typography variant="body1"
                                marginTop={1} fontWeight={900} color="white">Single-Speaker</Typography>
                    <Typography variant="subtitle2"
                                marginTop={1} fontWeight={500} color="text.secondary">The active speaker takes the full screen.</Typography>
                </CardContent>
            </Card>
        </Stack>
    )
}

const RecordingSettingsCard = ({
                                   onClose,
                                   roomName,
                                   selectedVideoResolution,
                                   selectedVideoLayout,
                                   noiseCancellationEnabled,
                                   selectedLanguage
                               }) => {

    const clickAwayHandler = (event) => {
        const cardRect = {
            top: 'calc(50% - 400px)',
            left: 'calc(50% - 325px)',
            width: 600,
            height: 750
        };

        // Calculate the actual pixel values for top and left
        const cardTop = window.innerHeight / 2 - 400;
        const cardLeft = window.innerWidth / 2 - 325;
        const cardBottom = cardTop + cardRect.height;
        const cardRight = cardLeft + cardRect.width;

        // Get the click coordinates
        const {clientX, clientY} = event;

        // Check if the click is inside the card's bounding box
        if (clientX >= cardLeft && clientX <= cardRight &&
            clientY >= cardTop && clientY <= cardBottom) {
            // If the click is inside the card, do not close it
            return;
        }

        onClose();
    }

    const updateNoiseCancellation = async ({noiseCancellationEnabled}) => {
        if (roomName === '') {
            return;
        }

        const entityRef = doc(db, kRoomStateCollectionName, roomName);
        try {
            await updateDoc(entityRef, {backgroundNoiseCancellationEnabled: noiseCancellationEnabled});
        } catch (error) {
            console.error('Error updating recording type for room: ', error);
        }
    }

    const updateVideoResolution = async (videoResolution) => {
        if (roomName === '') {
            return;
        }

        const entityRef = doc(db, kRoomStateCollectionName, roomName);
        try {
            await updateDoc(entityRef, {videoRecordingQuality: videoResolution});
        } catch (error) {
            console.error('Error updating recording type for room: ', error);
        }
    }

    const updateVideoLayout = async (videoLayout) => {

        if (roomName === '') {
            return;
        }

        const entityRef = doc(db, kRoomStateCollectionName, roomName);
        try {
            await updateDoc(entityRef, {videoRecordingLayout: videoLayout});
        } catch (error) {
            console.error('Error updating recording type for room: ', error);
        }

    }

    const updateTranscriptionLanguage = async (languageCode) => {
        if (roomName === '') {
            return;
        }

        const entityRef = doc(db, kRoomStateCollectionName, roomName);
        try {
            await updateDoc(entityRef, {transcriptionLanguage: languageCode});
        } catch (error) {
            console.error('Error updating recording type for room: ', error);
        }
    }

    return (
        <ClickAwayListener onClickAway={clickAwayHandler}>
            <Card
                elevation={5}
                style={{
                    cursor: 'pointer',
                    position: 'fixed',
                    top: 'calc(50% - 420px)',
                    left: 'calc(50% - 325px)',
                    width: '600px',
                    backgroundColor: 'rgba(50, 50, 50, 1)', // Dark theme color
                    borderRadius: '16px',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
                    overflow: 'visible',
                    height: '850px',
                    zIndex: 1000,
                }}
            >
                <CardContent style={{color: 'white'}}>
                    <IconButton onClick={onClose} sx={{position: 'absolute', right: 8, top: 8}} variant="contained">
                        <CloseIcon style={{fontSize: "1.0rem", color: "white"}}/>
                    </IconButton>
                    <Box display="flex" alignItems="center" justifyContent="left" marginTop={4}>
                        <Typography variant="h5" gutterBottom fontWeight={900}>
                            Recording settings
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" marginTop={2}>
                        <Typography variant="h6" gutterBottom fontWeight={900}>
                            Audio
                        </Typography>
                    </Box>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between"
                           marginBottom={0} marginTop={0}>
                        <Typography variant="body1" gutterBottom fontWeight={700} color="white">
                            Background noise cancellation
                        </Typography>
                        <Switch checked={noiseCancellationEnabled}
                                onChange={() => updateNoiseCancellation({noiseCancellationEnabled: !noiseCancellationEnabled})}
                                inputProps={{'aria-label': 'controlled'}}
                                sx={{
                                    color: 'white', // color when off
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: 'white', // color of the thumb when on
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: 'white', // color of the track when on
                                    },
                                    marginBottom: 0
                                }}
                        />
                    </Stack>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" gutterbottom>
                        <Typography variant="subtitle2" gutterBottom fontWeight={400} color="text.secondary">
                            Reduce background noise like air conditioners and laptop fans for all participants. This
                            usually ensures the best audio quality.
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" marginTop={4}>
                        <Typography variant="h6" gutterBottom fontWeight={900}>
                            Video
                        </Typography>
                    </Box>
                    <VideoQualitySelectCard selectedVideoQuality={selectedVideoResolution}
                                            updateVideoResolution={updateVideoResolution}/>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" marginTop={4}>
                        <Typography variant="h6" gutterBottom fontWeight={900}>
                            Video Recording Layout
                        </Typography>
                    </Box>
                    <VideoLayoutSelectCard selectedVideoLayout={selectedVideoLayout} updateVideoLayout={updateVideoLayout}/>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" marginTop={4}>
                        <Typography variant="h6" gutterBottom fontWeight={900}>
                            Transcription language
                        </Typography>
                    </Box>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between"
                           marginBottom={0}>
                        <Typography variant="subtitle2" gutterBottom color="text.secondary">
                            Set the language being spoken in the studio. You can change it at any time.
                        </Typography>
                        <TranscriptionLanguageSelectDropdown selectedLanguage={selectedLanguage}
                                                             updateTranscriptionLanguage={updateTranscriptionLanguage}/>
                    </Stack>

                </CardContent>
            </Card>
        </ClickAwayListener>
    );
};

export default RecordingSettingsCard;
