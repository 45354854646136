import {FormControl, Select} from "@mui/material";
import React from "react";
import MenuItem from "@mui/material/MenuItem";

const languages = {
    "Auto Detect": "auto",
    "English": "en",
    "Spanish": "es",
    "French": "fr",
    "German": "de",
    "Chinese (Mandarin, Simplified)": "zh",
    "Portuguese": "pt",
    "Italian": "it",
    "Bulgarian": "bg",
    "Catalan": "ca",
    "Czech": "cs",
    "Danish": "da",
    "Dutch": "nl",
    "Estonian": "et",
    "Finnish": "fi",
    "Flemish": "nl-BE",
    "German (Switzerland)": "de-CH",
    "Greek": "el",
    "Hindi": "hi",
    "Hungarian": "hu",
    "Romanian": "ro",
    "Russian": "ru",
    "Slovak": "sk",
    "Swedish": "sv",
    "Thai": "th",
    "Turkish": "tr",
    "Ukrainian": "uk",
    // "Vietnamese": "vi"
    // "Indonesian": "id",
    // "Japanese": "ja",
    // "Korean": "ko",
    // "Latvian": "lv",
    // "Lithuanian": "lt",
    // "Malay": "ms",
    // "Norwegian": "no",
    // "Polish": "pl",
};


const baseModelLanguages = {
    "Auto Detect": "auto",
    "Chinese": "zh",
    "Danish": "da",
    "Dutch": "nl",
    "English": "en",
    "Flemish": "nl",
    "French": "fr",
    "German": "de",
    "Hindi": "hi",
    "Indonesian": "id",
    "Italian": "it",
    "Japanese": "ja",
    "Korean": "ko",
    "Norwegian": "no",
    "Polish": "pl",
    "Portuguese": "pt",
    "Russian": "ru",
    "Spanish": "es",
    "Swedish": "sv",
    "Tamasheq": "taq",
    "Turkish": "tr",
    "Ukrainian": "uk"
};


const TranscriptionLanguageSelectDropdown = ({ selectedLanguage, updateTranscriptionLanguage, isBaseModel }) => {

    const languageSet = isBaseModel ? baseModelLanguages : languages;

    return (
        <FormControl style={{ width: '220px', height: '35px', marginBottom: '20px' }}>
            <Select
                value={selectedLanguage}
                onChange={(event) => updateTranscriptionLanguage(event.target.value)}
                sx={{
                    color: 'white', // Color of the text in the select box
                    backgroundColor: 'black', // Background color of the select box
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white', // Border color of the select box
                    },
                    '&:hover': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white', // Border hover color
                        }
                    },
                    '& .MuiSelect-icon': {
                        color: 'white' // Color of the dropdown icon
                    },
                    height: '35px',
                }}
                MenuProps={{
                    sx: {
                        '& .MuiMenu-paper': {
                            bgcolor: 'rgba(50, 50, 50, 1)', // Background color of the dropdown
                            color: 'white', // Text color for dropdown options
                            borderRadius: '10px', // Rounded corners
                        }
                    }
                }}
            >
                {Object.entries(languageSet).map(([language, code]) => (
                    <MenuItem key={code} value={code}>
                        {language}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default TranscriptionLanguageSelectDropdown;

