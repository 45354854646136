import {Box, Button, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {collection, doc, onSnapshot, query, updateDoc, where} from "firebase/firestore";
import {db} from "../Firebase";
import {kRoomStateCollectionName, RoomName} from "../utils/CollectionConstants";
import {LibrettoPlan} from "../utils/utils";

const RecordVideoShowVideo = 0;
const ShowVideoRecordAudio = 1;
const NoVideoRecordAudio = 2;

const RecordingTypeToggle = ({recordingInProgress, setRecordingAndStudioType, roomName, userPlan}) => {
    const [state, setState] = useState(NoVideoRecordAudio);

    const buttonsDisabled = recordingInProgress || roomName === '';

    const isVideoRecordingDisabled = Number(userPlan) === LibrettoPlan.AppSumoTier1;

    const recordVideoButtonDisabled = isVideoRecordingDisabled ? true : buttonsDisabled;

    const handleState = async (state) => {
        if (roomName === '') {
            return;
        }

        if (state != ShowVideoRecordAudio && state != NoVideoRecordAudio && state != RecordVideoShowVideo) {
            state = NoVideoRecordAudio;
        }

        const entityRef = doc(db, kRoomStateCollectionName, roomName);
        try {
            await updateDoc(entityRef, {recordingType: state});
        } catch (error) {
            console.error('Error updating recording type for room: ', error);
        }
    }

    useEffect(() => {
        if (roomName === '') {
            return;
        }

        const q = query(collection(db, kRoomStateCollectionName), where(RoomName, "==", roomName));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newRoomState = [];
            querySnapshot.forEach((doc) => {
                newRoomState.push(doc.data());
            });
            if (newRoomState.length > 0) {
                setRecordingAndStudioType(newRoomState[0].recordingType);
                setState(newRoomState[0].recordingType);
            }
        });

        return () => unsubscribe();
    }, [roomName, setRecordingAndStudioType]);

    return (
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2, marginRight: '10px'}}>
            <Button
                variant={state === RecordVideoShowVideo ? 'contained' : 'outlined'}
                onClick={() => handleState(RecordVideoShowVideo)}
                sx={{width: 230, borderRadius: '5px', height: '35px'}}
                disabled={recordVideoButtonDisabled}
            >
                <Typography variant="subtitle1">Record Video</Typography>
            </Button>
            <Button
                variant={state === ShowVideoRecordAudio ? 'contained' : 'outlined'}
                onClick={() => handleState(ShowVideoRecordAudio)}
                sx={{width: 230, borderRadius: '5px', height: '35px'}}
                disabled={buttonsDisabled}
            >
                <Typography variant="subtitle1">Record Audio, Show Video</Typography>
            </Button>
            <Button
                variant={state === NoVideoRecordAudio ? 'contained' : 'outlined'}
                onClick={() => handleState(NoVideoRecordAudio)}
                sx={{width: 230, borderRadius: '5px', height: '35px'}}
                disabled={buttonsDisabled}
            >
                <Typography variant="subtitle1">Record Audio, No Video</Typography>
            </Button>
        </Box>
    )
}

const ViewOnlyRecordingTypeToggle = ({recordingType}) => {

    const buttonsDisabled = true;

    return (
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
            <Button
                variant={recordingType === RecordVideoShowVideo ? 'contained' : 'outlined'}
                sx={{width: 230, borderRadius: '5px', height: '35px'}}
                disabled={buttonsDisabled}
            >
                <Typography variant="subtitle1">Record Video</Typography>
            </Button>
            <Button
                variant={recordingType === ShowVideoRecordAudio ? 'contained' : 'outlined'}
                sx={{width: 230, borderRadius: '5px', height: '35px'}}
                disabled={buttonsDisabled}
            >
                <Typography variant="subtitle1">Record Audio, Show Video</Typography>
            </Button>
            <Button
                variant={recordingType === NoVideoRecordAudio ? 'contained' : 'outlined'}
                sx={{width: 230, borderRadius: '5px', height: '35px'}}
                disabled={buttonsDisabled}
            >
                <Typography variant="subtitle1">Record Audio, No Video</Typography>
            </Button>
        </Box>
    )
}

export {RecordingTypeToggle, ViewOnlyRecordingTypeToggle};
