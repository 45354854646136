import React from 'react';

// Forward15 Icon
export const Forward15Icon = ({ size = 26 }) => (
    <svg
        width={size}
        height={(size * 28) / 26} // Maintain aspect ratio
        viewBox="0 0 26 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11 19V12L9 13.3125"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14 18.5627C14.3134 18.8373 14.7029 19 15.125 19C16.1605 19 17 18.0206 17 16.8125C17 15.6044 16.1605 14.625 15.125 14.625C14.7029 14.625 14.3134 14.7877 14 15.0623L14.375 12H17"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.1839 6.80412C26.0309 11.1977 26.2961 18.579 21.7762 23.2905C17.2563 28.0021 9.66302 28.2599 4.81605 23.8663C-0.0309169 19.4726 -0.296081 12.0914 4.22379 7.37984C6.7947 4.69988 10.36 3.46089 13.8486 3.69923M12.1429 1L15.1992 3.83294L12.1429 6.87098"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

// MaximizeVideo Icon
export const MaximizeVideoIcon = ({ size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4 9L4 6C4 4.89543 4.89543 4 6 4L9 4"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M20 15V18C20 19.1046 19.1046 20 18 20H15"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15 4L18 4C19.1046 4 20 4.89543 20 6L20 9"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9 20L6 20C4.89543 20 4 19.1046 4 18L4 15"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

// MinimizeVideo Icon
export const MinimizeVideoIcon = ({ size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9 4V7C9 8.10457 8.10457 9 7 9H4"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15 20L15 17C15 15.8954 15.8954 15 17 15L20 15"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M20 9L17 9C15.8954 9 15 8.10457 15 7L15 4"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4 15L7 15C8.10457 15 9 15.8954 9 17L9 20"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

// Pause Icon
export const PauseIcon = ({ size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.2857 3.11111C14.2857 2.49746 14.7974 2 15.4286 2H18.8571C19.4883 2 20 2.49746 20 3.11111V20.8889C20 21.5025 19.4883 22 18.8571 22H15.4286C14.7974 22 14.2857 21.5025 14.2857 20.8889V3.11111Z"
            fill="white"
        />
        <path
            d="M4 3.11111C4 2.49746 4.51167 2 5.14286 2H8.57143C9.20261 2 9.71429 2.49746 9.71429 3.11111V20.8889C9.71429 21.5025 9.20261 22 8.57143 22H5.14286C4.51167 22 4 21.5025 4 20.8889V3.11111Z"
            fill="white"
        />
    </svg>
);

// Play Icon
export const PlayIcon = ({ size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.99 11.7773C22.9219 10.8388 22.4205 9.92777 21.4859 9.39398L7.48782 1.39936C5.48785 0.25713 3 1.70127 3 4.00443V19.9937C3 22.2968 5.48785 23.741 7.48781 22.5988L21.4859 14.6041C22.4205 14.0703 22.9219 13.1593 22.99 12.2208C23.0226 12.0751 23.0226 11.9231 22.99 11.7773Z"
            fill="white"
        />
    </svg>
);

// Rewind15 Icon
export const Rewind15Icon = ({ size = 26 }) => (
    <svg
        width={size}
        height={(size * 28) / 26} // Maintain aspect ratio
        viewBox="0 0 26 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11 19V12L9 13.3125"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14 18.5627C14.3134 18.8373 14.7029 19 15.125 19C16.1605 19 17 18.0206 17 16.8125C17 15.6044 16.1605 14.625 15.125 14.625C14.7029 14.625 14.3134 14.7877 14 15.0623L14.375 12H17"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.81605 6.80412C-0.0309168 11.1977 -0.296082 18.579 4.22379 23.2905C8.74366 28.0021 16.337 28.2599 21.1839 23.8663C26.0309 19.4726 26.2961 12.0914 21.7762 7.37984C19.2053 4.69988 15.64 3.46089 12.1514 3.69923M13.8571 1L10.8008 3.83294L13.8571 6.87098"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

// VerticalEllipsis Icon
export const VerticalEllipsisIcon = ({ size = 20 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="10" cy="3" r="2" fill="white" />
        <circle cx="10" cy="10" r="2" fill="white" />
        <circle cx="10" cy="17" r="2" fill="white" />
    </svg>
);

// VolumeHigh Icon
export const VolumeHighIcon = ({ size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.8025 8C21.9331 9.31562 22 10.65 22 12C22 13.35 21.9331 14.6844 21.8025 16"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
        <path
            d="M17.8767 9C17.9583 9.9892 17.9999 10.9897 17.9999 12C17.9999 13.0103 17.9583 14.0108 17.8767 15"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
        <path
            d="M13 12C13 9.45813 12.7849 6.9701 12.373 4.55567C12.2494 3.83122 11.4028 3.51321 10.8175 3.95772L7.53627 6.44984C7.18835 6.71409 6.76348 6.85714 6.3266 6.85714H3C1.89543 6.85714 1 7.75257 1 8.85714V15.1429C1 16.2474 1.89543 17.1429 3 17.1429H6.3266C6.76348 17.1429 7.18835 17.2859 7.53627 17.5502L10.8175 20.0423C11.4027 20.4868 12.2494 20.1688 12.373 19.4443C12.7849 17.0299 13 14.5419 13 12Z"
            fill="white"
            stroke="white"
            strokeLinejoin="round"
        />
    </svg>
);

// VolumeLow Icon
export const VolumeLowIcon = ({ size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.8767 9C17.9583 9.9892 17.9999 10.9897 17.9999 12C17.9999 13.0103 17.9583 14.0108 17.8767 15"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
        <path
            d="M13 12C13 9.45813 12.7849 6.9701 12.373 4.55567C12.2494 3.83122 11.4028 3.51321 10.8175 3.95772L7.53627 6.44984C7.18835 6.71409 6.76348 6.85714 6.3266 6.85714H3C1.89543 6.85714 1 7.75257 1 8.85714V15.1429C1 16.2474 1.89543 17.1429 3 17.1429H6.3266C6.76348 17.1429 7.18835 17.2859 7.53627 17.5502L10.8175 20.0423C11.4027 20.4868 12.2494 20.1688 12.373 19.4443C12.7849 17.0299 13 14.5419 13 12Z"
            fill="white"
            stroke="white"
            strokeLinejoin="round"
        />
    </svg>
);

// VolumeMuted Icon
export const VolumeMutedIcon = ({ size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 12C13 9.45813 12.7849 6.9701 12.373 4.55567C12.2494 3.83122 11.4028 3.51321 10.8175 3.95772L7.53627 6.44984C7.18835 6.71409 6.76348 6.85714 6.3266 6.85714H3C1.89543 6.85714 1 7.75257 1 8.85714V15.1429C1 16.2474 1.89543 17.1429 3 17.1429H6.3266C6.76348 17.1429 7.18835 17.2859 7.53627 17.5502L10.8175 20.0423C11.4027 20.4868 12.2494 20.1688 12.373 19.4443C12.7849 17.0299 13 14.5419 13 12Z"
            fill="white"
            stroke="white"
            strokeLinejoin="round"
        />
        <path
            d="M17 9L23 15M17 15L23 9"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

// LargePlayButton Icon
export const LargePlayButtonIcon = ({ size = 98 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 98 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_bi_40_104)">
            <circle cx="49" cy="49" r="48" fill="black" fillOpacity="0.25" />
            <circle
                cx="49"
                cy="49"
                r="48.5"
                stroke="url(#paint0_linear_40_104)"
                strokeOpacity="0.2"
            />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.496 51.5942C68.4975 50.4398 68.4975 47.5513 66.496 46.3968L40.4989 31.4017C38.4989 30.2481 36 31.6916 36 34.0004V63.9906C36 66.2995 38.4989 67.7429 40.4989 66.5893L66.496 51.5942Z"
            fill="white"
        />
        <defs>
            <filter
                id="filter0_bi_40_104"
                x="-20"
                y="-20"
                width="138"
                height="138"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_40_104"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_40_104"
                    result="shape"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1.46045"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect2_innerShadow_40_104"
                />
                <feOffset dx="-0.730227" dy="0.730227" />
                <feGaussianBlur stdDeviation="0.365113" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0"
                />
                <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect2_innerShadow_40_104"
                />
            </filter>
            <linearGradient
                id="paint0_linear_40_104"
                x1="85.7742"
                y1="7.58065"
                x2="49"
                y2="97"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="white" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);
