import {useTheme} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import BlurOnIcon from '@mui/icons-material/BlurOn';

const BlurBackgroundButton = ({ blurBackground, setBlurBackground, disabled }) => {
    const theme = useTheme();

    const handleClick = () => {
        setBlurBackground(!blurBackground);
    }

    return (
        <Tooltip title={blurBackground ? "Unblur" : "Blur background"}>
            <IconButton onClick={handleClick} disabled={disabled} sx={{
                color: theme.palette.primary.main, // Set color from theme
                fontSize: '2rem', // Increase the font size
                // You can also adjust the size of the icon itself
                '& .MuiSvgIcon-root': { fontSize: '2rem' },
                border: blurBackground ? `1px solid ${theme.palette.primary.main}` : '1px solid transparent'
            }}>
                <BlurOnIcon/>
            </IconButton>
        </Tooltip>
    );
}

export default BlurBackgroundButton;