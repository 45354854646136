/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const NewLiveSoundboardFeature = () => {

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box>
            <Box marginBottom={4}>
                <Box marginBottom={2}>
                    <Typography
                        variant="h3"
                        color={'#2B6BFD'}
                        align={'flex-start'}
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        Live Soundboard
                    </Typography>
                    <Typography
                        component="p"
                        color="text.secondary"
                        fontFamily={'Inter'}
                        fontSize="1.2rem"
                        fontWeight={500}
                        align={'flex-start'}
                    >
                        With Libretto, you can insert any audio clips live as you record. Enhance your podcast by adding custom intro, ads, or any audio enhancements on the fly. Soundboard audio
                        is recorded on its own track and can be edited separately.
                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={2} paddingTop={1} justifyContent={'center'} marginLeft={isMobile ? 0.05 : 0}>
                <Box component={Card} boxShadow={4} height={1} width={1}>
                    <Box
                        component={CardMedia}
                        height={1}
                        width={1}
                        minHeight={isMobile ? 100 : 150}
                        style={{
                            backgroundSize: 'cover',  // Ensures the image is scaled down to fit inside the container without cropping
                            backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                            backgroundPosition: 'center', // Centers the image within the container
                            backgroundColor: 'transparent',
                        }}
                        image={'https://storage.googleapis.com/libretto-public-assets/Soundboard.png'} // Use "/other-image.png" for xs screens
                    />
                </Box>
            </Grid>
        </Box>
    );
};

const LiveSoundboardFeature = () => {

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box>
            <Box marginBottom={4}>
                <Box marginBottom={2}>
                    <Typography
                        variant="h3"
                        color="text.primary"
                        align={'flex-start'}
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        Live
                        {' '}<Typography
                        color={'primary'}
                        component={'span'}
                        variant={'inherit'}
                    >
                        Soundboard
                    </Typography>
                    </Typography>
                    <Typography
                        variant="h6"
                        component="p"
                        color="text.secondary"
                        sx={{ fontWeight: 400 }}
                        align={'flex-start'}
                    >
                        With Libretto, you can insert any audio clips live as you record. Enhance your podcast by adding custom intro, ads, or any audio enhancements on the fly. Soundboard audio
                        is recorded on its own track and can be edited separately.
                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={2} paddingTop={1} justifyContent={'center'} marginLeft={isMobile ? 0.05 : 0}>
                <Box component={Card} boxShadow={4} height={1} width={1}>
                    <Box
                        component={CardMedia}
                        height={1}
                        width={1}
                        minHeight={isMobile ? 100 : 150}
                        style={{
                            backgroundSize: 'contain',  // Ensures the image is scaled down to fit inside the container without cropping
                            backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                            backgroundPosition: 'center', // Centers the image within the container
                            backgroundColor: '#222B45',
                        }}
                        image={isMobile ? 'https://storage.googleapis.com/libretto-public-assets/DarkLiveboard.png' : 'https://storage.googleapis.com/libretto-public-assets/DarkSBoard.png'} // Use "/other-image.png" for xs screens
                    />
                </Box>
            </Grid>
        </Box>
    );
};

export default LiveSoundboardFeature;
