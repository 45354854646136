import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {useConfirm} from "material-ui-confirm";

const BackButton = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate('/dashboard'); // Replace with your route
    };

    return (
        <IconButton onClick={handleBackClick} sx={{
            color: theme.palette.primary.main, // Set color from theme
            fontSize: '2rem', // Increase the font size
            // You can also adjust the size of the icon itself
            '& .MuiSvgIcon-root': { fontSize: '2rem' }
        }}>
            <ArrowBackIcon/>
        </IconButton>
    );
};

const RecordingBackButton = ({recordingInProgress, stopRecording}) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const confirm = useConfirm();

    const navigateToDashboard = () => {
        navigate("/dashboard");
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }

    const handleBackClick = () => {
        if (!recordingInProgress) {
            navigateToDashboard();
            return;
        }
        confirmAndGoBack();
    };

    const confirmAndGoBack = async () => {
        confirm({
            description: `Are you sure? This will stop the recording.`,
            confirmationButtonProps: {variant: "contained", color: "error"},
            cancellationButtonProps: {variant: "contained", color: "primary"}
        }).then(() => stopRecording().then(() => navigateToDashboard()).catch((error) => console.error("Error stopping recording: ", error))).catch(() => console.log("Back cancelled"));
    };


    return (
        <IconButton onClick={handleBackClick} sx={{
            color: theme.palette.primary.main, // Set color from theme
            fontSize: '2rem', // Increase the font size
            // You can also adjust the size of the icon itself
            '& .MuiSvgIcon-root': { fontSize: '2rem' }
        }}>
            <ArrowBackIcon/>
        </IconButton>
    );
};

export {BackButton, RecordingBackButton};
