/* eslint-disable react/no-unescaped-entities */
import React, {useState} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "@mui/material/Card";


export const NewStudioQualityRecording = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
        if (viewPortEntered) {
            return;
        }

        setViewPortEntered(isVisible);
    };

    return (
        <Box>
            <Grid container spacing={isMd ? 4 : 0} direction={isMd ? 'row' : 'column-reverse'} alignItems="center">
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={7}
                    sx={{
                        display: { xs: 'flex' },
                    }}
                >
                    <Box component={Card} boxShadow={4} height={1} width={1}>
                        <Box
                            component={CardMedia}
                            height={1}
                            width={1}
                            minHeight={{ xs: 210, sm: 280, md: 370 }}
                            image="https://storage.googleapis.com/libretto-public-assets/compare.png"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box marginBottom={4}>
                        <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom>
                            <Typography
                                color={'#2B6BFD'}
                                component={'span'}
                                variant={'inherit'}
                            >
                                Studio Quality recording
                            </Typography>
                        </Typography>
                        <Typography component={'p'} color={'text.secondary'} fontFamily={"Inter"} fontSize="1.2rem" fontWeight={500} marginBottom={3}>
                            Libretto records 48K 16-bit raw audio and upto 4K resolution video locally, ensuring picture-perfect quality regardless of internet connection.
                        </Typography>
                        <Typography component={'p'} color={'text.secondary'} fontFamily={"Inter"} fontSize="1.2rem" fontWeight={500}>
                            Recordings become available on your dashboard as soon as a session ends.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const StudioQualityRecording = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
        if (viewPortEntered) {
            return;
        }

        setViewPortEntered(isVisible);
    };

    return (
        <Box>
            <Grid container spacing={isMd ? 4 : 0} direction={isMd ? 'row' : 'column-reverse'} alignItems="center">
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={7}
                    sx={{
                        display: { xs: 'flex' },
                    }}
                >
                    <Box component={Card} boxShadow={4} height={1} width={1}>
                        <Box
                            component={CardMedia}
                            height={1}
                            width={1}
                            minHeight={{ xs: 210, sm: 280, md: 370 }}
                            image="https://storage.googleapis.com/libretto-public-assets/compare.png"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box marginBottom={4}>
                        <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom>
                            <Typography
                                color={'primary'}
                                component={'span'}
                                variant={'inherit'}
                            >
                                Studio Quality
                            </Typography>{' '}
                            recording
                        </Typography>
                        <Typography component={'p'} color={'text.secondary'} fontSize="1.2rem" fontWeight={500} marginBottom={3}>
                            Libretto records 48K 16-bit raw audio and upto 4K resolution video locally, ensuring picture-perfect quality regardless of internet connection.
                        </Typography>
                        <Typography component={'p'} color={'text.secondary'} fontSize="1.2rem" fontWeight={500}>
                            Recordings become available on your dashboard as soon as a session ends.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default StudioQualityRecording;
