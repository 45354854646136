import {useTheme} from "@mui/material/styles";
import {useParams} from "react-router-dom";
import React, {useContext, useRef, useState} from "react";
import {FetchContext} from "../../context/FetchContext";
import NewGuestStudio from "./NewGuestStudio";
import NewLobby from "../NewStudio/NewLobby";

const NewGuestStudioAndLobby = () => {
    const theme = useTheme();

    const { roomName, userFriendlyRoomName } = useParams();
    const fetchContext = useContext(FetchContext);
    const [preJoinChoices, setPreJoinChoices] = useState(undefined);
    const guestTokenRef = useRef(null);
    const [navigateToStudio, setNavigateToStudio] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [hostIdentity, setHostIdentity] = useState('');

    const readableUserFriendlyRoomName = userFriendlyRoomName ? userFriendlyRoomName.replace(/-/g, ' ') : '';

    const joinStudioAsGuest = async (username) => {
        try {
            const {data} = await fetchContext.authAxios.post(`/guest/${roomName}`, {
                    identity: username,
                },
                {
                    headers: {
                        Authorization: `Bearer `,
                    }
                });
            guestTokenRef.current = data.token;
            setHostIdentity(data.hostIdentity);
            setNavigateToStudio(true);
        } catch (err) {
            console.log("Error is ", err)
            if (err.response.status === 404) {
                setErrorMessage("The studio you are trying to join does not exist, or the host is not ready yet. Please try again later.");
                return;
            }
            if (err.response.status === 409) {
                setErrorMessage("There's already a participant in the studio with that name. Use a different name and try again.");
                return;
            }
            if (err.response.status === 507) {
                setErrorMessage("There's already the maximum number of participants in the studio. Please contact the host.");
                return;
            }
            if (err.response.status === 423) {
                setErrorMessage("Room is locked. Please try again later.");
                return;
            }
            setErrorMessage("Something went wrong. Please try again later.");
        }
    }


    const handlePreJoinSubmit = async (values) => {
        setPreJoinChoices(values);
        joinStudioAsGuest(values.username);
    }

    if (navigateToStudio) {
        return (
            <NewGuestStudio userChoices={preJoinChoices} guestToken={guestTokenRef.current} roomName={roomName} userFriendlyStudioName={readableUserFriendlyRoomName} hostIdentity={hostIdentity}/>
        )
    }

    return (<NewLobby onSubmit={handlePreJoinSubmit} userFriendlyStudioName={readableUserFriendlyRoomName} errorMessage={errorMessage} isHost={false}/>);
}

export default NewGuestStudioAndLobby;
