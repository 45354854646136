import React, {useContext, useEffect, useRef, useState} from "react";
import {PreJoin} from "@livekit/components-react";
import '@livekit/components-styles';
import Studio from "./Studio";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RecordingTypeTabs from "./RecordingTypeTabs";
import {useParams} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Container from "../../components/Container";
import {useTheme} from "@mui/material/styles";
import {AuthContext} from "../../context/AuthContext";
import LobbyTopbar from "./LobbyTopbar";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../Firebase";
import {kLibrettoUserCollectionName} from "../../utils/CollectionConstants";
import NewStudio from "../NewStudio/NewStudio";


const StudioAndLobby = () => {
    const theme = useTheme();

    const [preJoinChoices, setPreJoinChoices] = useState(undefined);
    const [recordingOptions, setRecordingOptions] = useState(undefined);
    const [userFriendlyRoomName, setUserFriendlyRoomName] = useState(undefined);

    const recordingStateRef = useRef();

    const {projectId} = useParams();

    const handlePreJoinSubmit = (values) => {
        setRecordingOptions(recordingStateRef.current);
        setPreJoinChoices(values);
    }

    const authContext = useContext(AuthContext);

    const logoutUser = async () => {
        await authContext.logout();
    }

    const userId = authContext.getUserId();

    useEffect(() => {
        const fetchUserData = async () => {
            const entityRef = doc(db, kLibrettoUserCollectionName, userId);
            try {
                const docSnap = await getDoc(entityRef);
                if (docSnap.exists()) {
                    setUserFriendlyRoomName(docSnap.data().userFriendlyRoomName);
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching title: ', error);
            }
        };

        if (userId) {
            fetchUserData();
        }
    }, [userId]);

    const Lobby = () => {
        return (
            <Box>
                <AppBar
                    position={'fixed'}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        // borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                    }}
                    elevation={0}
                >
                    <Container maxWidth={1} paddingY={{ xs: 1, sm: 1.5 }}>
                        <LobbyTopbar handleLogout={logoutUser} />
                    </Container>
                </AppBar>
                <main>
                    <Box height={{ xs: 50, sm: 50, md: 50 }} />
            <Grid container paddingX={4} paddingY={6}>
                <Grid container item justifyContent="center" alignItems="center" direction="column" spacing={2}>
                    <Grid item>
                        <Typography gutterBottom variant="h2" color="primary" fontWeight={900} fontFamily='lato'>New recording</Typography>
                    </Grid>
                    {userFriendlyRoomName && <RecordingTypeTabs handlePreJoinSubmit={handlePreJoinSubmit} ref={recordingStateRef} userFriendlyRoomName={userFriendlyRoomName}/>}
                </Grid>
            </Grid>
                </main>
            </Box>
        )
    }

    return (
        preJoinChoices === undefined ?
                <Lobby/> :
                <Studio userChoices={preJoinChoices} recordingOptions={recordingOptions} projectId={projectId} logoutUser={logoutUser}/>
    );
}

export default StudioAndLobby;
