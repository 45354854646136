import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


export function NewCustomAudioVisualizer({ audioDestination }) {
    const svgWidth = 200;
    const svgHeight = 90;
    const barCount = 50; // Number of bars in the visualizer
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const animationFrameRef = useRef(null);
    const [bars, setBars] = useState(Array(barCount).fill(0));

    useEffect(() => {
        if (!audioDestination) return;

        // The audioContext is now derived from the audioDestination's context
        audioContextRef.current = audioDestination.context;
        analyserRef.current = audioContextRef.current.createAnalyser();

        // Create a source from the audioDestination's stream
        const source = audioContextRef.current.createMediaStreamSource(audioDestination.stream);
        source.connect(analyserRef.current);

        analyserRef.current.fftSize = 256; // Smaller fftSize for better time domain resolution
        const bufferLength = analyserRef.current.frequencyBinCount;
        dataArrayRef.current = new Uint8Array(bufferLength);

        const draw = () => {
            analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

            // Normalize and scale the slice of data we're interested in
            const scaledBars = Array.from(dataArrayRef.current)
                .slice(0, barCount)
                .map(n => {
                    // Scale to fit SVG height, ensuring no negative values
                    // Adjust the scaling factor as needed
                    const height = Math.abs(n - 128) * (svgHeight / 128);
                    return Math.max(1, height); // Ensure a minimum height to avoid invisible bars
                });

            setBars(scaledBars);

            animationFrameRef.current = requestAnimationFrame(draw);
        };

        draw();

        return () => {
            cancelAnimationFrame(animationFrameRef.current);
            source.disconnect();
            analyserRef.current.disconnect();
            // Don't close the audioContextRef.current as it's now external
        };
    }, [audioDestination]);

    const theme = useTheme();

    const isXl = useMediaQuery(theme.breakpoints.up('xl'));
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));

    const height = isXl ? "52px" : (isLg ? "52px" : "52px");
    const width = isXl ? "179px" : (isLg ? "157px" : "145px");

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${svgWidth} ${svgHeight}`}
            style={{ backgroundColor: '#000', borderRadius: '10px' }} // Example background color
        >
            {bars.map((barHeight, idx) => (
                <rect
                    key={idx}
                    x={idx * (svgWidth / barCount)}
                    y={(svgHeight - barHeight) / 2}
                    width={svgWidth / barCount - 1}
                    height={barHeight}
                    fill="white"
                />
            ))}
        </svg>
    );
}
