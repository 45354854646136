import {
    LiveKitRoom,
} from "@livekit/components-react";
import '@livekit/components-styles';
import {Grid, Typography} from "@mui/material";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {CLIENT_URL, FetchContext} from "../../context/FetchContext";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {collection, doc, onSnapshot, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {
    kRoomStateCollectionName,
    RoomName, RoomNotRecording,
} from "../../utils/CollectionConstants";
import Soundboard from "./Soundboard";
import AppBar from "@mui/material/AppBar";
import Container from "../../components/Container";
import {useTheme} from "@mui/material/styles";
import {RecordingStoppedCard} from "./RecordingStoppedCard";
import StudioTopbar from "../../components/StudioTopbar";
import {
    StudioAudioConference,
    StudioAudioConferenceWithVideo,
    StudioVideoConference
} from "../../components/LibrettoConference";
import {GridVideoLayout, RefreshTokenAndRetry, StandardVideoResolution} from "../../utils/utils";

const serverUrl = 'wss://librettobeta-htow58t4.livekit.cloud'

const Studio = ({userChoices, recordingOptions, projectId, logoutUser}) => {

    // States
    const [cloudEgressStarted, setCloudEgressStarted] = useState(false);   // This means cloud egress has been started by the backend.
    const [recordingInProgress, setRecordingInProgress] = useState(false); // This is the client UI state to show flashing red and timer.
    const [countdown, setCountdown] = useState(null);
    const [studioData, setStudioData] = useState({token: '', roomName: '', userFriendlyRoomName: '', userPlan: null});
    const [inviteLink, setInviteLink] = useState('');
    const [soundboardToken, setSoundboardToken] = useState('');
    const [showStoppedCard, setShowStoppedCard] = useState(false);
    const navigate = useNavigate();
    const [assetId, setAssetId] = useState(null);
    const [recordingStarting, setRecordingStarting] = useState(false);
    const [localRecordingInProgress, setLocalRecordingInProgress] = useState(false)
    const [localRecordingDone, setLocalRecordingDone] = useState(false);
    const [selectedVideoResolution, setSelectedVideoResolution] = useState(StandardVideoResolution);
    const [selectedVideoLayout, setSelectedVideoLayout] = useState(GridVideoLayout);
    const [noiseCancellationEnabled, setNoiseCancellationEnabled] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const [blurBackground, setBlurBackground] = useState(false);

    const [soundBoardRecordingDone, setSoundBoardRecordingDone] = useState(false);
    const [localRecordingStopped, setLocalRecordingStopped] = useState(false);

    const [triggerReload, setTriggerReload] = useState(false);

    const [recordingAndStudioType, setRecordingAndStudioType] = useState(2);

    useEffect(() => {
        setTriggerReload(prev => !prev);
    }, [selectedVideoResolution, recordingAndStudioType]);

    const recordingAndStudioTypeRef = useRef(recordingAndStudioType);

    useEffect(() => {
        recordingAndStudioTypeRef.current = recordingAndStudioType;
    }, [recordingAndStudioType]);

    const GetRecordingType = ({recordingAndStudioType}) => {
        if (recordingAndStudioType === 0) {
            return "Video";
        }
        return "AudioOnly";
    }

    const theme = useTheme();

    const handleCloseCard = () => {
        setLocalRecordingStopped(false);
    }
    const handleNavigateToAsset = () => {
        navigate("/assets/" + assetId);
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }

    useEffect(() => {
        if (studioData.roomName !== '') {

            const q = query(collection(db, kRoomStateCollectionName), where(RoomName, "==", studioData.roomName));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const newRoomState = [];
                querySnapshot.forEach((doc) => {
                    newRoomState.push(doc.data());
                });
                if (newRoomState.length > 0) {
                    setNoiseCancellationEnabled(newRoomState[0].backgroundNoiseCancellationEnabled);
                    setSelectedVideoResolution(newRoomState[0].videoRecordingQuality);
                    setSelectedVideoLayout(newRoomState[0].videoRecordingLayout ? newRoomState[0].videoRecordingLayout : GridVideoLayout);
                    setSelectedLanguage(newRoomState[0].transcriptionLanguage);
                    // RoomNotRecording
                    if (newRoomState[0].roomState === 0) {
                        setRecordingInProgress(false);
                        setCloudEgressStarted(false);
                        setCountdown(null);
                        setRecordingStarting(false);
                    }
                    // RoomRecordingStarting
                    if (newRoomState[0].roomState === 1 && countdown === null) {
                        setRecordingStarting(true);
                        setCountdown(5);
                    }
                    // RoomRecordingInProgress
                    if (newRoomState[0].roomState === 2 && !cloudEgressStarted) {
                        setCloudEgressStarted(true);
                    }
                }
            });

            return () => unsubscribe();
        } else {

        }
    }, [studioData]);

    const urlFriendlyUserFriendlyRoomName = ({userFriendlyRoomName}) => {
        return userFriendlyRoomName.replace(/ /g, '-');
    }

    const guestURL = (roomName, userFriendlyRoomName) => {
        return CLIENT_URL + "/guest/" + roomName + "/" + urlFriendlyUserFriendlyRoomName({userFriendlyRoomName});
    }

    // Contexts
    const fetchContext = useContext(FetchContext);
    const auth = useContext(AuthContext);

    const tokenOptions = useMemo(() => {
        return {
            userInfo: {
                identity: userChoices.username,
                name: userChoices.username,
            },
        };
    }, [userChoices.username]);


    const startRecording = async () => {
        setShowStoppedCard(false);
        try {
            const response = await fetchContext.authAxios.post('/record', {
                projectId: projectId,
                type: GetRecordingType({recordingAndStudioType: recordingAndStudioTypeRef.current}),
                title: recordingOptions.title ? recordingOptions.title : 'My podcast episode',
                hostIdentity: tokenOptions.userInfo.identity,
                livekitToken: studioData.token,
            }, {
                headers: {
                    Authorization: `Bearer ${auth.getToken()}`,
                }
            });
            console.log("Asset id: ", response.data.assetId)
            setAssetId(response.data.assetId);
        } catch (error) {
            if (error.response.status === 401) {
                await RefreshTokenAndRetry(error, auth, fetchContext);
            }
        }
    };

    const stopRecording = async () => {
        try {
            await fetchContext.authAxios.post(`/stop`, {
                assetId: assetId,
            }, {
                headers: {
                    Authorization: `Bearer ${auth.getToken()}`,
                }
            });
        } catch (error) {
            if (error.response.status === 401) {
                await RefreshTokenAndRetry(error, auth, fetchContext);
            }
        }
    };

    // The client UI state.
    useEffect(() => {
        if (cloudEgressStarted && countdown === null) {
            setRecordingInProgress(true);
        }
    }, [cloudEgressStarted, countdown]);

    useEffect(() => {
        setLocalRecordingInProgress(recordingStarting || recordingInProgress);
    }, [recordingStarting, recordingInProgress]);

    // Countdown before recording starts.
    useEffect(() => {
        let intervalId;
        if (countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setCountdown(null);
        }

        // Clear interval on unmount or countdown change
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [countdown]);

    // Join studio when component loads. And make sure the room state is recording not started.
    useEffect(() => {

        const updateRoomState = async ({roomName}) => {
            if (roomName === '') {
                return;
            }

            const entityRef = doc(db, kRoomStateCollectionName, roomName);
            try {
                await updateDoc(entityRef, {roomState: RoomNotRecording});
            } catch (error) {
                console.error('Error updating recording type for room: ', error);
            }
        }
        const joinStudio = async () => {
            try {
                const {data} = await fetchContext.authAxios.post('/join', {
                    identity: tokenOptions.userInfo.identity,
                }, {
                    headers: {
                        Authorization: `Bearer ${auth.getToken()}`,
                    }
                });
                updateRoomState({roomName: data.roomName});
                setStudioData({token: data.token, roomName: data.roomName, userFriendlyRoomName: data.userFriendlyRoomName, userPlan: data.userPlan});
                setInviteLink(guestURL(data.roomName, data.userFriendlyRoomName));
            } catch (err) {
                if (err && err.response.status === 401) {
                    await RefreshTokenAndRetry(err, auth, fetchContext);
                }
                if (err.code === "ERR_NETWORK") {
                    await auth.logout();
                }
            }
        }

        joinStudio();
    }, [auth, fetchContext, tokenOptions]);

    // Add the soundboard to the studio as a hidden participant when the component loads.
    useEffect(() => {
        if (studioData.token === '')
            return;
        const joinSoundboard = async () => {
            try {
                const {data} = await fetchContext.authAxios.post('/soundboard', {
                    identity: "libretto_user_soundboard_" + studioData.roomName,
                }, {
                    headers: {
                        Authorization: `Bearer ${auth.getToken()}`,
                    }
                });
                setSoundboardToken(data.token);
            } catch (err) {
                if (err.code === "ERR_NETWORK") {
                    await auth.logout();
                }
            }
        }
        joinSoundboard();
    }, [studioData]);

    const onLeave = async () => {
        if (recordingInProgress) {
            await stopRecording();
        }
        navigate("/dashboard")
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }

    return (
        <Box>
            <AppBar
                position={'fixed'}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    // borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                }}
                elevation={0}
            >
                <Container maxWidth={1} paddingY={{ xs: 1, sm: 1.5 }}>
                    <StudioTopbar handleLogout={logoutUser} stopRecording={stopRecording} recordingInProgress={recordingInProgress} userPlan={studioData.userPlan} inviteLink={inviteLink} setRecordingAndStudioType={setRecordingAndStudioType} noiseCancellationEnabled={noiseCancellationEnabled} selectedVideoResolution={selectedVideoResolution} selectedVideoLayout={selectedVideoLayout} selectedLanguage={selectedLanguage} roomName={studioData.roomName}/>
                </Container>
            </AppBar>
            <main>
                <Box height={{ xs: 50, sm: 50, md: 50 }} />
            <Grid container direction="column" paddingX={8}>
                <Grid item xl={1.5} lg={2} md={2} paddingTop={4} paddingBottom={1}>
                    {soundboardToken ? <Soundboard token={soundboardToken} projectId={projectId} localRecordingInProgress={localRecordingInProgress} setLocalRecordingDone={setSoundBoardRecordingDone}/> : <Typography>Loading Soundboard...</Typography>}
                </Grid>
                <Grid item x1={10.5} lg={10} md={10}>
                    <LiveKitRoom key={triggerReload} serverUrl={serverUrl} token={studioData.token} data-lk-theme="default"
                                 video={userChoices.videoEnabled} audio={userChoices.audioEnabled}
                                 style={{height: recordingAndStudioType === 2 ? '20vh' : '70vh', minHeight: '40px', maxHeight: '1080px', width: '100%', maxWidth: '1920px', borderRadius: '10px'}}
                                 connect={true} onDisconnected={onLeave}>
                        {
                            recordingAndStudioType === 2 ?
                                <StudioAudioConference
                                    recordingInProgress={recordingInProgress}
                                    startRecording={startRecording}
                                    stopRecording={stopRecording}
                                    enableKrispNoiseCancellation={noiseCancellationEnabled}
                                    localRecordingInProgress={localRecordingInProgress}
                                    setLocalRecordingDone={setLocalRecordingDone}
                                    setLocalRecordingStopped={setLocalRecordingStopped}
                                    livekitToken={studioData.token}
                                /> :
                                recordingAndStudioType === 1 ?
                                    <StudioAudioConferenceWithVideo
                                        recordingInProgress={recordingInProgress}
                                        startRecording={startRecording}
                                        stopRecording={stopRecording}
                                        enableKrispNoiseCancellation={noiseCancellationEnabled}
                                        localRecordingInProgress={localRecordingInProgress}
                                        setLocalRecordingDone={setLocalRecordingDone}
                                        blurBackground={blurBackground}
                                        setBlurBackground={setBlurBackground}
                                        setLocalRecordingStopped={setLocalRecordingStopped}
                                        livekitToken={studioData.token}
                                    /> :
                                    <StudioVideoConference
                                        recordingInProgress={recordingInProgress}
                                        startRecording={startRecording}
                                        localRecordingDone={localRecordingDone}
                                        stopRecording={stopRecording}
                                        enableKrispNoiseCancellation={noiseCancellationEnabled}
                                        localRecordingInProgress={localRecordingInProgress}
                                        livekitToken={studioData.token}
                                        setLocalRecordingDone={setLocalRecordingDone}
                                        blurBackground={blurBackground}
                                        setBlurBackground={setBlurBackground}
                                        setLocalRecordingStopped={setLocalRecordingStopped}
                                        videoResolution={selectedVideoResolution}
                                    />
                        }
                    </LiveKitRoom>
                </Grid>
                <Grid item alignSelf="center">
                    {countdown !== null && countdown > 0 && (<Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="rgba(0, 0, 0, 0.7)" // Semi-transparent background
                    >
                        <Box
                            component="span"
                            p={1}
                            fontSize="5rem"
                            fontWeight={700}
                            color="primary.main"
                        >
                            {countdown}
                        </Box>
                    </Box>)}
                </Grid>
                <Grid item md={2}>
                    {localRecordingStopped && (<RecordingStoppedCard onClose={handleCloseCard} onNavigate={handleNavigateToAsset} localRecordingDone={localRecordingDone} soundboardRecordingDone={soundBoardRecordingDone}/>)}
                </Grid>
            </Grid>
            </main>
        </Box>
    );
}

export default Studio;
