import React, {useState} from 'react';
import {Button, Box, Typography, TextField} from '@mui/material';
import Grid from "@mui/material/Grid";
import {PreJoin} from "@livekit/components-react";

const AudioOnlyRecordingType = "AudioOnly";
const VideoRecordingType = "Video";

const WearingHeadphonesSetup = "WearingHeadphones";
const NotWearingHeadphonesSetup = "NotWearingHeadphones";

const RecordingTypeTabs = React.forwardRef(({handlePreJoinSubmit, userFriendlyRoomName}, stateRef) => {

    const onSubmit = (values) => {
        handlePreJoinSubmit(values);
    };

    const onValidate = (values) => {
        return values.username.length > 0;
    }

    return (
        <Grid container direction="column" item justifyContent="center" alignItems="center" spacing={2}>
            <Grid container item justifyContent="center" alignItems="center" spacing={3}>
                <Grid item>
                    <RecordingTypeTabsInner userFriendlyRoomName={userFriendlyRoomName} stateRef={stateRef}/>
                </Grid>
                <Grid item>
                    <PreJoin style={{borderRadius: '5px', maxWidth: '400px' }} joinLabel='Enter Studio'
                             onError={(err) => console.log('error while setting up prejoin', err)}
                             defaults={{
                                 username: '',
                                 videoEnabled: true,
                                 audioEnabled: true,
                             }}
                             onValidate={onValidate}
                             onSubmit={onSubmit} data-lk-theme="default"
                    />
                </Grid>
            </Grid>
        </Grid>
    )
});

const RecordingTypeTabsInner = ({userFriendlyRoomName, stateRef}) => {

    const [state, setState] = React.useState({
        selectedRecordingType: AudioOnlyRecordingType,
        title: '',
        selectedAudioSetup: NotWearingHeadphonesSetup,
    });

    // Use useEffect to update the ref with the current state
    React.useEffect(() => {
        if (stateRef) {
            stateRef.current = state;
        }
    }, [state, stateRef]);

    const setTitle = (newTitle) => {
        setState((prevState) => ({ ...prevState, title: newTitle }));
    };

    const setSelectedAudioSetup = (newSetup) => {
        setState((prevState) => ({ ...prevState, selectedAudioSetup: newSetup }));
    };

    const setEnableKrispNoiseCancellation = (newEnableKrispNoiseCancellation) => {
        setState((prevState) => ({ ...prevState, enableKrispNoiseCancellation: newEnableKrispNoiseCancellation }));
    }

    return (
        <Grid container>
            <Grid item container direction="column" spacing={0}>
                <Grid item marginBottom={0.5}>
                    <Grid item marginBottom={0.5}>
                        <Typography variant="subtitle2" fontWeight={700} color="text.secondary">
                            You are about to enter {userFriendlyRoomName}.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item marginBottom={0.5}>
                    <Typography fontSize={"1.125rem"} fontWeight={600}>Recording Title</Typography>
                </Grid>
                <Grid item marginBottom={2.5}>
                    <TextField
                        fullWidth
                        id="title"
                        variant="outlined"
                        placeholder="My podcast episode"
                        style={{height: '40px'}}
                        InputProps={{style: {height: '40px'}}}
                        value={state.title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Grid>
                <Grid item marginBottom={2.5}>
                    <Typography fontSize={"1.325rem"} fontWeight={900}>Let's check your camera and mic</Typography>
                </Grid>
                <Grid item marginBottom={0.5}>
                    <Grid item>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                            <Button
                                variant={state.selectedAudioSetup === WearingHeadphonesSetup ? 'contained' : 'outlined'}
                                onClick={() => setSelectedAudioSetup(WearingHeadphonesSetup)}
                                sx={{width: 220, borderRadius: '5px', height: '35px'}}
                            >
                                <Typography variant="subtitle1">I'm using headphones.</Typography>
                            </Button>
                            <Button
                                variant={state.selectedAudioSetup === NotWearingHeadphonesSetup ? 'contained' : 'outlined'}
                                onClick={() => setSelectedAudioSetup(NotWearingHeadphonesSetup)}
                                sx={{width: 220, borderRadius: '5px', height: '35px'}}
                            >
                                <Typography variant="subtitle1">Not using headphones.</Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item marginBottom={2.5}>
                    <Typography variant="subtitle2">
                        Knowing this will allow Libretto to optimize audio recording.
                    </Typography>
                </Grid>
                <Grid item marginBottom={0.5}>
                    <Typography variant="subtitle2" fontWeight={700} color="text.secondary">
                        You are joining as a host.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default RecordingTypeTabs;
