/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import KeyboardAltIcon from '@mui/icons-material/KeyboardAlt';

const mock = [
    {
        title: 'High-quality remote recording',
        subtitle:
            'Record high-definition audio and video with remote participants. Get studio-quality recordings without a professional setup.',
        icon: (
            <VideoCameraFrontIcon/>
        ),
    },
    {
        title: 'Edit video by editing text',
        subtitle:
            'Libretto transcribes your recordings and uploads, letting you edit it the way you edit a doc. No need to scrub through audio or video.',
        icon: (
            <KeyboardAltIcon/>
        ),
    },
    {
        title: 'One-click audio post production',
        subtitle:
            'Instantly improve audio quality of any recording. Automated speech leveling, noise reduction, and more.',
        icon: (
            <GraphicEqIcon/>
        ),
    },
];

export const NewServices = () => {
    const theme = useTheme();
    return (
        <Box>
            <Box marginBottom={4}>
                <Box marginBottom={2}>
                    <Typography
                        variant="h3"
                        color="text.primary"
                        align={'center'}
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        Studio-quality recording + editing
                    </Typography>
                    <Typography
                        variant="h6"
                        component="p"
                        fontFamily={"Inter"}
                        color="text.secondary"
                        sx={{ fontWeight: 400 }}
                        align={'center'}
                    >
                        Record high-quality audio & video, and edit on an intelligent and collaborative editor.
                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={2}>
                {mock.map((item, i) => (
                    <Grid item xs={12} md={4} key={i}>
                        <Box width={1} height={1}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                            >
                                <Box
                                    component={Avatar}
                                    width={60}
                                    height={60}
                                    marginBottom={2}
                                    bgcolor={alpha(theme.palette.primary.main, 0.1)}
                                    color={"#2B6BFD"}
                                >
                                    {item.icon}
                                </Box>
                                <Typography
                                    variant={'h6'}
                                    gutterBottom
                                    sx={{ fontWeight: 500 }}
                                    align={'center'}
                                >
                                    {item.title}
                                </Typography>
                                <Typography align={'center'} color="text.secondary" fontFamily={"Inter"} fontSize={"1.1rem"} fontWeight={500}>
                                    {item.subtitle}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

const Services = () => {
    const theme = useTheme();
    return (
        <Box>
            <Box marginBottom={4}>
                <Box marginBottom={2}>
                    <Typography
                        variant="h3"
                        color="text.primary"
                        align={'center'}
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        Podcasting made
                        {' '}<Typography
                            color={'primary'}
                            component={'span'}
                            variant={'inherit'}
                        >
                            simple
                        </Typography>
                    </Typography>
                    <Typography
                        variant="h6"
                        component="p"
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                        align={'center'}
                    >
                        Record high-quality audio & video, and edit with a collaborative editor and automated audio post production.
                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={2}>
                {mock.map((item, i) => (
                    <Grid item xs={12} md={4} key={i}>
                        <Box width={1} height={1}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                            >
                                <Box
                                    component={Avatar}
                                    width={60}
                                    height={60}
                                    marginBottom={2}
                                    bgcolor={alpha(theme.palette.primary.main, 0.1)}
                                    color={theme.palette.primary.main}
                                >
                                    {item.icon}
                                </Box>
                                <Typography
                                    variant={'h6'}
                                    gutterBottom
                                    sx={{ fontWeight: 500 }}
                                    align={'center'}
                                >
                                    {item.title}
                                </Typography>
                                <Typography align={'center'} color="text.secondary" fontSize={"1.1rem"} fontWeight={500}>
                                    {item.subtitle}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Services;
