import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {useTheme} from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {Card, CardActions, CardContent, ClickAwayListener, IconButton} from "@mui/material";

const InviteLinkCard = ({ inviteLink, handleClose }) => {

    const [inviteLinkCopied, setInviteLinkCopied] = useState(false);

    const theme = useTheme();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(inviteLink);
        setInviteLinkCopied(true);
    };

    const copyButtonText = inviteLinkCopied ? 'Copied!' : 'Copy';

    return (
        <ClickAwayListener onClickAway={handleClose}>
        <Card
            elevation={5}
            style={{ cursor: 'pointer', position: 'absolute',
                top: 65,
                right: 75,
                maxWidth: 480,
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background
                borderRadius: '16px', // Rounded corners
                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)', overflow: 'visible', height: 190 }}
        >
            <CardContent>
                <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }} variant="contained">
                    <CloseIcon style={{fontSize: "1.0rem", color: "white"}}/>
                </IconButton>
                <Typography variant="h6" marginBottom={1} color="white" fontWeight={700}>
                    Share link to invite guests
                </Typography>
                <Typography variant="subtitle2" color="text.secondary" fontSize="1.0rem">
                    Use this link to invite guests to your studio. You can invite up to 8 guests.
                </Typography>
            </CardContent>
            <CardActions style={{ marginTop: '-10px', paddingLeft: '12px', justifyContent: 'space-between'}}>
                <TextField
                    variant="outlined"
                    size="small"
                    value={inviteLink}
                    InputProps={{
                        readOnly: true,
                        style: { backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white', width: '370px' }, // Adjust text color if needed
                    }}
                    style={{ borderRadius: '7px' }}
                />
                <Button
                    variant="contained"
                    onClick={copyToClipboard}
                    style={{ height: '40px', width: '80px', borderRadius: '10px' }} // Adjust button colors as needed
                >
                    <Typography fontWeight={600}>
                        {copyButtonText}
                    </Typography>
                </Button>
            </CardActions>
        </Card>
        </ClickAwayListener>
    );
};

export default InviteLinkCard;



