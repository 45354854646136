import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, {useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";


const ShareRecordingFeature = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
        if (viewPortEntered) {
            return;
        }

        setViewPortEntered(isVisible);
    };

    const videoHeight = isMd ? 410 : 210;
    const videoWidth = isMd ? 728 : 370;

    const [isHovered, setIsHovered] = useState(false);

    return (
        <Box>
            <Grid container spacing={isMd ? 4 : 0} direction={isMd ? 'row' : 'column'} alignItems="center">
                <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                        <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom>
                            <Typography
                                color={'#2B6BFD'}
                                component={'span'}
                                variant={'inherit'}
                            >
                                Collaborate and Share
                            </Typography>
                        </Typography>
                        <Typography fontSize={500} fontFamily={"Inter"} color={'text.secondary'} fontSize="1.2rem" marginBottom={4}>
                            Invite your editor, co-host, or guest to collaboratively edit your podcast in real-time.
                        </Typography>
                        <Typography fontSize={500} fontFamily={"Inter"} color={'text.secondary'} fontSize="1.2rem" marginBottom={4}>
                            Share a recording, an edit or a whole project with team members by simply sharing a link.
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={8}
                    sx={{
                        display: { md: 'flex' },
                    }}
                >
                    <Box component={Card} boxShadow={4} height={1} width={1}>
                        <Box
                            component={CardMedia}
                            height={1}
                            width={1}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            style={{borderRadius: '10px'}}
                            minHeight={{ xs: 170, sm: 280, md: 370 }}
                            image="/colab.png"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ShareRecordingFeature;
