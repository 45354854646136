import {useTheme} from "@mui/material/styles";
import React, {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AvatarMenu from "../../components/AvatarMenu";

const LobbyTopbar = ({handleLogout}) => {

    const theme = useTheme();

    const authContext = useContext(AuthContext);

    const email = authContext.getUserEmail();

    const photoUrl = authContext.getUserPhotoUrl();

    const navigate = useNavigate();

    const navigateToSettings = () => {
        navigate("/settings");
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigateToDashboard = () => {
        navigate("/dashboard");
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }

    const handleLogoutFromLobby = () => {
        handleLogout();
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
        >
            <Box
                display={'flex'}
                alignItems="center" // This ensures that the items are vertically centered
            >
                <IconButton onClick={navigateToDashboard} sx={{
                    color: theme.palette.primary.main, // Set color from theme
                    fontSize: '2rem', // Increase the font size
                    // You can also adjust the size of the icon itself
                    '& .MuiSvgIcon-root': { fontSize: '2rem' }
                }}>
                    <ArrowBackIcon/>
                </IconButton>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
                <Box marginLeft={0.5}>
                    <AvatarMenu handleClick={handleClick} handleLogout={handleLogoutFromLobby} anchorEl={anchorEl} open={Boolean(anchorEl)} handleClose={handleClose} photoUrl={photoUrl} email={email} navigateToSettings={navigateToSettings}/>
                </Box>
            </Box>
        </Box>
    );
};

export default LobbyTopbar;
