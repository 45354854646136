import {Box, Button, Card, CardActions, CardContent, Grid} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {FetchContext} from "../../context/FetchContext";
import {AuthContext} from "../../context/AuthContext";
import {RefreshTokenAndRetry, UploadToLibretto} from "../../utils/utils";
import CardMedia from "@mui/material/CardMedia";


const BrandLogos = React.memo(({projectId, selectedBrandLogo, updateStudioBrandLogo}) => {
    const fetchContext = useContext(FetchContext);
    const authContext = useContext(AuthContext);

    const [logosArray, setLogosArray] = useState([]);
    const [hoveredCard, setHoveredCard] = useState(null);

    const addLogoHidden = useMemo(() => logosArray.length >= 3, [logosArray.length]);

    const dedupArray = useCallback((array) => {
        const seen = new Set();
        return array.filter(item => {
            if (item.assetId === null || item.url === null || item.assetId && seen.has(item.assetId)) {
                return false;
            } else {
                if (item.assetId) seen.add(item.assetId);
                return true;
            }
        });
    }, []);

    const deleteAsset = useCallback(async ({assetId}) => {
        await fetchContext.authAxios.delete(`/assets/${assetId}`, {
            headers: {
                Authorization: `Bearer ${authContext.getToken()}`,
            }
        });
    }, [fetchContext.authAxios, authContext]);

    const handleDelete = useCallback(({index, assetId}) => {
        if (assetId) {
            deleteAsset({assetId});
        }

        if (selectedBrandLogo && selectedBrandLogo.assetId === assetId) {
            updateStudioBrandLogo({assetId: null, url: null})
        }

        setLogosArray(prevLogos => {
            const newLogosArray = [...prevLogos];
            newLogosArray.splice(index, 1);
            return dedupArray(newLogosArray);
        });
    }, [deleteAsset, dedupArray]);

    const handleUpload = useCallback(async (event) => {
        // Log mime type
        const mimeType = event.target.files[0].type;
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (!allowedTypes.includes(mimeType)) {
            console.error("Invalid file type. Please upload a PNG or JPEG image.");
            // You might want to show this error to the user in your UI
            return;
        }

        const file = event.target.files[0];

        // Prevent upload if file is too large. Greater than 5MB
        if (file.size > 5000000) {
            console.error("File is too large. Please upload a file smaller than 5MB.");
            return;
        }

        if (file) {
            let assetId;
            try {
                assetId = await UploadToLibretto({inputRef: event.target, setProgress: null, filetype: "BrandLogo", projectId: projectId, editId: "", fetchContext, authContext});
                console.log("Logo uploaded with assetId: ", assetId)
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    await RefreshTokenAndRetry(e, authContext, fetchContext);
                }
                console.error("Error uploading logo:", e);
                return;
            }

            setLogosArray(prevLogos => {
                const newLogo = {url: URL.createObjectURL(file), assetId: assetId};
                const updatedLogos = [...prevLogos, newLogo];
                return dedupArray(updatedLogos);
            });
        }
    }, [projectId, fetchContext, authContext, dedupArray]);

    useEffect(() => {
        const getBrandLogos = async () => {
            try {
                const response = await fetchContext.authAxios.get(`/brandlogos/${projectId}`, {
                    headers: {
                        Authorization: `Bearer ${authContext.getToken()}`,
                    },
                });
                return response.data.logos;
            } catch (error) {
                console.error("Error fetching logos:", error);
                return [];
            }
        };

        getBrandLogos().then((logos) => {
            const logosArray = logos.map(logo => {
                return {
                    url: logo ? logo.url : null,
                    assetId: logo ? logo.assetId : null,
                }
            });setLogosArray(dedupArray(logosArray));
        });
    }, [projectId, fetchContext.authAxios, authContext, dedupArray]);


    const handleCardClick = useCallback(({assetId, url}) => {
        updateStudioBrandLogo(selectedBrandLogo.assetId === assetId ? {assetId: null, url: null} : {assetId: assetId, url: url});
    }, [updateStudioBrandLogo, selectedBrandLogo]);

    return (
        <Grid container spacing={1.5} direction="row" alignSelf="center" justifySelf="center">
            {logosArray && logosArray.length > 0 && logosArray.map((logoObj, index) => (
                <Grid item key={index}>
                    <Card
                        onClick={(event) => handleCardClick({assetId: logoObj.assetId, url: logoObj.url})}
                        onMouseEnter={() => setHoveredCard(index)}
                        onMouseLeave={() => setHoveredCard(null)}
                        elevation={5}
                        style={{
                            cursor: 'pointer',
                            position: 'relative',
                            border: selectedBrandLogo && selectedBrandLogo.assetId === logoObj.assetId ? `1.5px solid #2B6BFD` : '1px solid #e8e8e8',
                            overflow: 'visible',
                            height: 90,
                            width: 110,
                        }}
                    >
                        <CardMedia
                            component="img"
                            image={logoObj.url}
                            alt="Logo"
                            style={{ height: '100%', width: '100%', borderRadius: "6px", objectFit: 'cover' }}
                        />
                        <CardActions style={{
                            display: hoveredCard === index ? 'block' : 'none',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: 0,
                            margin: '0',
                            zIndex: 1000,
                        }}>
                            <IconButton
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleDelete({index, assetId: logoObj.assetId});
                                }}
                                size="small"
                                style={{padding: '2px'}}
                            >
                                <Close style={{fontSize: "0.8rem"}}/>
                            </IconButton>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
            <Grid item hidden={addLogoHidden}>
                <Card variant="outlined" style={{borderStyle: 'solid', height: 90, width: 100}}>
                    <CardContent style={{textAlign: 'center', display: "flex", flexDirection: "Column"}}>
                        <Tooltip title="Add logo. PNG or JPEG images only.">
                            <Button component="label">
                                <AddIcon fontSize="large" sx={{color: "#1a1a1a"}}/>
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={handleUpload}
                                />
                            </Button>
                        </Tooltip>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
});

export default BrandLogos;
