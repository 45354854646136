import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import Container from '../../../components/Container';

import Footer, {NewFooter} from '../Components/Footer';
import Navbar, {NewNavbar} from "../../../components/Navbar";
import {AuthContext} from "../../../context/AuthContext";


const Main = ({ children, colorInvert = false, bgcolor = 'transparent', selectedPricingOption, hideSignupButton}) => {
    const theme = useTheme();
    const authContext = useContext(AuthContext);


    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 38,
    });

    return (
        <Box>
            <AppBar
                position={'sticky'}
                sx={{
                    top: 0,
                    backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
                }}
                elevation={trigger ? 1 : 0}
            >
                <Container paddingY={1}>
                    <Navbar selectedPricingOption={selectedPricingOption} signUpStateMessage={authContext.signUpStateMessage} signUpStateMessageOpen={authContext.signUpStateMessageOpen} handleSignupStateMessageClose={authContext.handleSignupStateMessageClose} signinWithGoogle={authContext.signinWithGoogle} signupWithGoogle={authContext.signupWithGoogle} loginFormOpen={authContext.loginFormOpen} handleLoginFormOpen={authContext.handleLoginFormOpen} handleLoginFormClose={authContext.handleLoginFormClose} signupFormOpen={authContext.signupFormOpen} handleSignupFormOpen={authContext.handleSignupFormOpen} handleSignupFormClose={authContext.handleSignupFormClose} signUpWithEmailAndPassword={authContext.signUpWithEmailAndPassword} signinWithEmail={authContext.signinWithEmail} hideSignupButton={hideSignupButton}/>
                </Container>
            </AppBar>
            <main>
                {children}
                <Divider />
            </main>
            <Container paddingY={4}>
                <Footer />
            </Container>
        </Box>
    );
};

export const NewMain = ({ children, colorInvert = false, bgcolor = 'transparent', selectedPricingOption, hideSignupButton, pageEnum}) => {
    const theme = useTheme();
    const authContext = useContext(AuthContext);


    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 38,
    });

    return (
        <Box>
            <AppBar
                position={'sticky'}
                sx={{
                    top: 0,
                    backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
                }}
                elevation={trigger ? 1 : 0}
            >
                <Container paddingY={1}>
                    <NewNavbar selectedPricingOption={selectedPricingOption} signUpStateMessage={authContext.signUpStateMessage} signUpStateMessageOpen={authContext.signUpStateMessageOpen} handleSignupStateMessageClose={authContext.handleSignupStateMessageClose} signinWithGoogle={authContext.signinWithGoogle} signupWithGoogle={authContext.signupWithGoogle} loginFormOpen={authContext.loginFormOpen} handleLoginFormOpen={authContext.handleLoginFormOpen} handleLoginFormClose={authContext.handleLoginFormClose} signupFormOpen={authContext.signupFormOpen} handleSignupFormOpen={authContext.handleSignupFormOpen} handleSignupFormClose={authContext.handleSignupFormClose} signUpWithEmailAndPassword={authContext.signUpWithEmailAndPassword} signinWithEmail={authContext.signinWithEmail} hideSignupButton={hideSignupButton} pageEnum={pageEnum}/>
                </Container>
            </AppBar>
            <main>
                {children}
                <Divider />
            </main>
            <Container paddingY={4}>
                <NewFooter />
            </Container>
        </Box>
    );
};

Main.propTypes = {
    children: PropTypes.node,
    colorInvert: PropTypes.bool,
    bgcolor: PropTypes.string,
};

export default Main;
