import CanvasStreamProxy from "./CanvasStreamProxy";
import FakeStreamFactory from "./FakeStreamFactory";

class CustomMediaRecorder {

    constructor(stream) {
        console.log("CustomMediaRecorder instantiated")
        this.fakeStreamFactory = new FakeStreamFactory();
        this.canvasStreamProxy = new CanvasStreamProxy();
        this.mediaRecorder = null;
        this.targetStream = null;
        this.ondataavailable = null;
        this.onstop = null;
        this.inputStream = stream;
    };

    async CreateTargetStream(stream) {
        stream = stream || new MediaStream();

        if (!(stream instanceof MediaStream)) {
            return Promise.reject(new Error('Invalid arugment'));
        }

        if (!stream.getTracks().filter((track) => {
            return track.kind === 'video';
        }).length) {
            const option = {
                videoType: 'black'
            };
            const fakeVideoTrack = this.fakeStreamFactory.getFakeVideoTrack(option);
            stream.addTrack(fakeVideoTrack);
        }

        const videoTrack = this.canvasStreamProxy.createCanvasStream(stream);
        const audioTrack = stream.getAudioTracks()[0];

        const resultStream = new MediaStream();
        resultStream.addTrack(videoTrack);
        resultStream.addTrack(audioTrack);

        return Promise.resolve(resultStream);
    }

    replaceVideoTrack = (track) => {
        console.log("REPLACE VIDEO TRACK FUNCTION")
        if (!(track instanceof MediaStreamTrack)) {
            console.error('Track must be an instance of MediaStreamTrack');
            return;
        }

        if (track.kind === 'video') {
            console.log("REPLACING VIDEO TRACK")
            const stream = new MediaStream();
            stream.addTrack(track);
            this.canvasStreamProxy.replaceVideoStream(stream);
        }
    }

    stop() {
        this.mediaRecorder.stop();
    }

    async start(timeslice, isHDQuality) {

        let recordingOptions = {
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 7000000,
            mimeType: "video/x-matroska; codecs=avc1.64001F",
        };

        if (isHDQuality) {
            recordingOptions = {
                audioBitsPerSecond: 128000,
                videoBitsPerSecond: 12000000,
                mimeType: "video/x-matroska; codecs=avc1.64002A",
            };
        }

        try {
            this.targetStream = await this.CreateTargetStream(this.inputStream);
        } catch (error) {
            console.error(error);
            return Promise.reject('TargetStream Error');
        }


        try {
            this.mediaRecorder = new MediaRecorder(this.targetStream, recordingOptions);
        } catch (error) {
            console.error(error);
            return Promise.reject('MediaRecorder Error');
        }

        this.mediaRecorder.onstop = this.onstop;
        this.mediaRecorder.ondataavailable = this.ondataavailable;
        this.mediaRecorder.start(timeslice); // collect 10s of data blobs
    }

}

export default CustomMediaRecorder;
