import * as React from "react";
import {Track} from "livekit-client";
import {
    LayoutContextProvider,
    TrackLoop,
    useTracks,
    Chat,
    ParticipantAudioTile
} from "@livekit/components-react";
import {CustomControlBar} from "./CustomControlBar";


export function CustomAudioConference({recordingInProgress, startRecording, stopRecording, isRecordButtonDisabled, localRecordingInProgress, ...props }) {
    const [widgetState, setWidgetState] = React.useState({
        showChat: false,
        unreadMessages: 0,
    });

    const audioTracks = useTracks([
        { source: Track.Source.Microphone }]);
    const unknownTracks = useTracks([ { source: Track.Source.Unknown }]);
    const soundboardTrack = unknownTracks.filter((track) => track.participant.identity === "Soundboard");
    const internalAgentParticipants = ["TimerAgent", "RecordingAgent"];
    const visibleTracks = audioTracks.filter((track) => !internalAgentParticipants.includes(track.participant.identity)).concat(soundboardTrack);

    return (
        <LayoutContextProvider onWidgetChange={setWidgetState}>
            <div className="lk-audio-conference" {...props}>
                <div className="lk-audio-conference-stage">
                    <TrackLoop tracks={visibleTracks}>
                        <ParticipantAudioTile />
                    </TrackLoop>
                </div>
                <CustomControlBar controls={{ microphone: true, screenShare: false, camera: false, chat: false, blurBackground: false }} recordingInProgress={recordingInProgress} startRecording={startRecording} stopRecording={stopRecording} isRecordButtonDisabled={isRecordButtonDisabled} localRecordingInProgress={localRecordingInProgress}/>
                {widgetState.showChat && <Chat />}
            </div>
        </LayoutContextProvider>
    );
}
