import {Card, Typography} from "@mui/material";
import React from "react";


const RecordingInProgress = ({recordingInProgress}) => {
    return (
        <>
        <style>
            {`
                    @keyframes flashing {
                        0% { opacity: 1; }
                        50% { opacity: 0; }
                        100% { opacity: 1; }
                    }
                `}
        </style>
    {recordingInProgress ? (
        <Card style={{ display: 'flex', alignItems: 'center', height: '35px', width: '100px', marginRight: '20px', border: '1px solid black', backgroundColor: 'black', borderRadius: '5px', padding: '10px' }}> {/* Adjust the height as needed */}
            <span style={{
                height: '20px',
                width: '20px',
                borderRadius: '50%',
                backgroundColor: 'red',
                marginRight: '5px',
                animation: 'flashing 1s infinite'
            }}></span>
            <Typography fontWeight={600} color="red">REC</Typography>
        </Card>
    ) : (
        <div style={{ height: '35px', width: '100px', marginRight: '20px' }}> {/* This div occupies space but is invisible */}
            {/* Placeholder to maintain layout but not show anything */}
        </div>
    )}
        </>
    )
}

export default RecordingInProgress;
