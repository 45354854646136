import React, {useContext, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../context/AuthContext";
import {useTheme} from "@mui/material/styles";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import InviteLinkCard from "../pages/Studio/Invite";
import {RecordingBackButton} from "./BackButton";
import RecordingInProgress from "./RecordingInProgress";
import {RecordingTypeToggle} from "./RecordingTypeToggle";
import AvatarMenu from "./AvatarMenu";
import {RecordingSettingsButton} from "./CoolButtons";
import RecordingSettingsCard from "../pages/Studio/RecordingSettingsCard";

const StudioTopbar = ({handleLogout, inviteLink, userPlan, stopRecording, recordingInProgress, setRecordingAndStudioType, roomName, selectedVideoResolution, noiseCancellationEnabled, selectedLanguage, selectedVideoLayout}) => {

    const theme = useTheme();
    const { mode } = theme.palette;

    const [showInviteCard, setShowInviteCard] = useState(false);
    const [showRecordingSettingsCard, setShowRecordingSettingsCard] = useState(false);

    const authContext = useContext(AuthContext);

    const email = authContext.getUserEmail();

    const photoUrl = authContext.getUserPhotoUrl();

    const navigate = useNavigate();

    const handleInviteButtonClick = () => {
        setShowInviteCard(!showInviteCard);
    }

    const handleInviteFormClose = () => {
        setShowInviteCard(false);
    }

    const navigateToSettings = () => {
        navigate("/settings");
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogOutFromStudio = () => {
        handleLogout();
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
        >
                <Box
                    display={'flex'}
                    alignItems="center" // This ensures that the items are vertically centered
                >
                    <RecordingBackButton recordingInProgress={recordingInProgress} stopRecording={stopRecording}/>
                    <RecordingInProgress recordingInProgress={recordingInProgress}/>
                    <RecordingTypeToggle recordingInProgress={recordingInProgress} setRecordingAndStudioType={setRecordingAndStudioType} roomName={roomName} userPlan={userPlan}/>
                    <RecordingSettingsButton handleClick={() => setShowRecordingSettingsCard(!showRecordingSettingsCard)} disabled={recordingInProgress}/>
                    {showRecordingSettingsCard && <RecordingSettingsCard onClose={() => setShowRecordingSettingsCard(false)} noiseCancellationEnabled={noiseCancellationEnabled} selectedLanguage={selectedLanguage} selectedVideoLayout={selectedVideoLayout} selectedVideoResolution={selectedVideoResolution} roomName={roomName}/>}
                </Box>
            <Box display={'flex'} alignItems={'center'}>
                <Box marginLeft={2} marginTop={0.5}>
                    <Button variant="contained" style={{borderRadius: "20px", height: "35px"}} onClick={handleInviteButtonClick}>
                        <PersonAddIcon style={{marginRight: '5px', fontSize: "1.2rem"}}/>
                        <Typography fontWeight={500}>Invite</Typography>
                    </Button>
                    {showInviteCard && <InviteLinkCard inviteLink={inviteLink} handleClose={handleInviteFormClose}/>}
                </Box>
                <Box marginLeft={0.5}>
                    <AvatarMenu handleClick={handleClick} handleLogout={handleLogOutFromStudio} anchorEl={anchorEl} open={Boolean(anchorEl)} handleClose={handleClose} photoUrl={photoUrl} email={email} navigateToSettings={navigateToSettings}/>
                </Box>
            </Box>
        </Box>
    );
};

export default StudioTopbar;
