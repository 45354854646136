
// These should be synced with the actual collection names in the Firestore database which are created by the backend.
const kAssetsCollectionName = "assets"
const kTracksCollectionName = "tracks"
const kProjectsCollectionName = "projects"
const kTranscriptsCollectionName = "transcripts"

const kEditsCollectionName = "edits"

const kRoomStateCollectionName = "rooms"

const RoomName = "roomName"

const RoomState = "roomState"

const EditId = "editId";
const EditTrackId = "trackId";
const EditAssetId = "assetId";
const EditTitle = "title";
const EditCreatedAt = "createdAt";

const EditUserId = "userId";

const RoomNotRecording = 0;
const RoomRecordingStarting = 1;
const RoomRecordingInProgress = 2;

const AssetId = "assetId";
const AssetUserId = "userId";
const AssetProjectId = "projectId";
const AssetTitle = "title";
const AssetType = "assetType";
const AssetSource = "source";
const AssetCreateTime = "createTime";
const AssetStatus = "status";
const AssetDeleted = "deleted";

const TranscriptId      = "transcriptId";
const TranscriptTrackId    = "trackId";
const TranscriptUserId     = "userId";
const TranscriptAssetId     = "assetId";
const TranscriptStatus      = "status";
const TranscriptObjectName  = "objectUrl";

const kLibrettoUserCollectionName = "users"


const TrackId = "trackId";
const TrackAssetId = "assetId";
const TrackUserId = "userId";
const TrackEgressId = "egressId";
const TrackTitle = "title";
const TrackType = "trackType";
const TrackStatus = "status";
const TrackStorageBucket = "storageBucket";
const TrackObjectName = "objectName";
const TrackResolution = "resolution";
const TrackSampleRate = "sampleRate";
const TrackAudioBitrate = "audioBitrate";
const TrackVideoBitrate = "videoBitrate";
const TrackDuration = "duration";
const TrackDeleted = "deleted";

const ProjectId = "projectId";
const ProjectUserId = "userId";
const ProjectName = "name";
const ProjectCreateTime = "createTime";
const ProjectUpdateTime = "updateTime";
const ProjectDeleted = "deleted";
const LibrettoUserId                = "userId"
const LibrettoUserEmail             = "email"
const LibrettoRoomName              = "roomName"
const LibrettoDisplayName           = "displayName"
const LibrettoUserFriendlyRoomName  = "userFriendlyRoomName"
const LibrettoUserPlan              = "plan"
const LibrettoUserAllowedToLogin    = "allowedToLogin"
export {
    kAssetsCollectionName,
    kTracksCollectionName,
    kRoomStateCollectionName,
    kProjectsCollectionName,
    kTranscriptsCollectionName,
    AssetId,
    AssetUserId,
    AssetProjectId,
    AssetTitle,
    AssetType,
    AssetSource,
    AssetCreateTime,
    AssetStatus,
    AssetDeleted,
    TranscriptAssetId,
    TranscriptId,
    TrackId,
    TrackAssetId,
    TrackUserId,
    TrackEgressId,
    TrackTitle,
    TrackStatus,
    TrackStorageBucket,
    TrackObjectName,
    TrackResolution,
    TrackSampleRate,
    TrackAudioBitrate,
    TrackVideoBitrate,
    TrackDuration,
    TrackDeleted,
    TranscriptUserId,
    ProjectId,
    ProjectUserId,
    ProjectName,
    ProjectCreateTime,
    ProjectUpdateTime,
    ProjectDeleted,
    RoomName,
    RoomState,
    kEditsCollectionName,
    kLibrettoUserCollectionName,
 LibrettoUserId           ,
 LibrettoUserEmail        ,
 LibrettoRoomName           ,
 LibrettoDisplayName         ,
 LibrettoUserFriendlyRoomName ,
 LibrettoUserPlan      ,
 LibrettoUserAllowedToLogin   ,
    RoomNotRecording,
    RoomRecordingStarting,
    RoomRecordingInProgress,
    TrackType,
    EditTitle,
    EditId,
    EditTrackId,
    EditAssetId,
    EditCreatedAt,
    EditUserId,
};

