import Box from "@mui/material/Box";
import {SettingsIcon, StudioBackIconButton} from "../NewStudio/SettingsIcon";
import {CreateWebSocket, LibrettoStudioTheme, RefreshTokenAndRetry, SerializeVideoChunks} from "../../utils/utils";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Grid";
import {useNavigate, useParams} from "react-router-dom";
import {
    AdjustWebcamPosition,
    AspectRatios, ScreenRecordButton,
    StartMicrophone,
    StartScreenCapture,
    StartWebcam, StopCapture
} from "./ScreenHelpers";
import {AudioInputSelector, ScreenShareButton, VideoInputSelector} from "./CustomSelect";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import ScreenRecordingSettingsCard from "./ScreenRecordingSettings";
import {BASE_WEBSOCKET_URL, FetchContext} from "../../context/FetchContext";
import CustomMediaRecorder from "../../components/MediaRecorder/CustomMediaRecorder";
import {NewRecordingStoppedCard} from "../Studio/RecordingStoppedCard";
import {AuthContext} from "../../context/AuthContext";

const WS_DATA_CHANNEL_CAPACITY = 2 * 10 * 1024 * 1024;
const MEDIA_RECORDER_TIME_SLICE_MS = 10000;
const EOS_MARKER = new Uint8Array([69, 79, 83, 45, 65, 85, 68, 73, 79]);

const ConsumeData = async (mediaData, consumeAll, recordingSocket) => {
    if (mediaData.length > 0) {

        if (consumeAll) {
            // console.log("Consuming all data");
        }

        let accumulatedChunks = [];
        let totalSize = 0;

        let sizeLimit = WS_DATA_CHANNEL_CAPACITY;
        if (consumeAll) {
            sizeLimit *= 1000;
        }

        while (mediaData.length > 0 && totalSize < sizeLimit) {
            const chunk = mediaData[0];
            const chunkSize = chunk.size;

            if (totalSize + chunkSize > WS_DATA_CHANNEL_CAPACITY) {
                break;
            }

            mediaData.shift();  // Consider optimizing this operation if audioData is large
            accumulatedChunks.push(chunk);
            totalSize += chunkSize;
        }

        if (accumulatedChunks.length > 0) {
            // console.log("Total size to be sent is ", totalSize)
            const serializedChunks = await SerializeVideoChunks(accumulatedChunks);
            // console.log("Serialized chunks has length ", serializedChunks.length);
            // console.log("Sending data over websocket")
            recordingSocket.send(serializedChunks);
            if (consumeAll) {
                console.log("Sending EOS_MARKER")
                recordingSocket.send(EOS_MARKER);
            }
        }
    } else if (consumeAll) {
        console.log("SENDING EOS_MARKER")
        recordingSocket.send(EOS_MARKER);
    }
};

const ScreenRecord = () => {
    const navigate = useNavigate();

    const {projectId} = useParams();

    const [screenStream, setScreenStream] = useState(null);
    const [webcamStream, setWebcamStream] = useState(null);
    const [micStream, setMicStream] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isMovingWebcam, setIsMovingWebcam] = useState(false);
    const [webcamPosition, setWebcamPosition] = useState({x: 20, y: 20});
    const [aspectRatio, setAspectRatio] = useState('16:9');
    const [displayMode, setDisplayMode] = useState('fit');
    const [webcamSize, setWebcamSize] = useState(225);
    const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
    const [webcamDevices, setWebcamDevices] = useState([]);
    const [micDevices, setMicDevices] = useState([]);
    const screenVideoRef = useRef(null);
    const webcamVideoRef = useRef(null);
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const videoContainerRef = useRef(null);

    const [isWebcamEnabled, setIsWebcamEnabled] = useState(false);
    const [isMicEnabled, setIsMicEnabled] = useState(false);

    const [recordingStopped, setRecordingStopped] = useState(false);
    const [recordingDone, setRecordingDone] = useState(false);
    const [showRecordingEndedCard, setShowRecordingEndedCard] = useState(true);

    const [dimensions, setDimensions] = useState({width: '100%', height: '100%'});

    const videoDataRef = useRef([]);
    const [recordingSocket, setRecordingSocket] = useState(null);
    const serverReceivedEos = useRef(false);
    const mediaRecorderRef = useRef(null);

    const audioContextRef = useRef(null);
    const mixedDestinationRef = useRef(null);
    const requestDataIntervalRef = useRef(null);

    const fetchContext = useContext(FetchContext);
    const auth = useContext(AuthContext);

    const [token, setToken] = useState(null);

    const [canvasStream, setCanvasStream] = useState(null);

    useEffect(() => {
        const joinStudio = async () => {
            try {
                const {data} = await fetchContext.authAxios.post('/join', {
                    identity: "ScreenRecordUser",
                }, {
                    headers: {
                        Authorization: `Bearer ${auth.getToken()}`,
                    }
                });
                setToken(data.token);
            } catch (err) {
                if (err && err.response.status === 401) {
                    await RefreshTokenAndRetry(err, auth, fetchContext);
                }
                if (err.code === "ERR_NETWORK") {
                    await auth.logout();
                }
            }
        }

        joinStudio();
    }, [auth, fetchContext]);

    useEffect(() => {
        const newAudioContext = new AudioContext({sampleRate: 48000, latencyHint: "playback"});
        const newMixedDestination = newAudioContext.createMediaStreamDestination();
        audioContextRef.current = newAudioContext;
        mixedDestinationRef.current = newMixedDestination;

        return () => {
            if (newAudioContext !== null && newAudioContext.state !== "closed") {
                newAudioContext.close();
            }
        };
    }, []);

    useEffect(() => {
        if (!isMicEnabled || !micStream) return;

        const sourceNode = audioContextRef.current.createMediaStreamSource(micStream);
        sourceNode.connect(mixedDestinationRef.current);

    }, [isMicEnabled, micStream]);

    useEffect(() => {
        if (!screenStream) return;

        const screenStreamAudioTrack = screenStream.getAudioTracks()[0];
        const sourceNode = audioContextRef.current.createMediaStreamSource(new MediaStream([screenStreamAudioTrack]));
        sourceNode.connect(mixedDestinationRef.current);

    }, [screenStream]);

    useEffect(() => {
        if (!token) return;
        const recordUrl = `${BASE_WEBSOCKET_URL}/ws/recording/screen/${projectId}/${token}`;
        const ws = CreateWebSocket(recordUrl, setRecordingSocket, (event) => {
            if (event.data === "EOS-AUDIO") {
                serverReceivedEos.current = true;
                console.log("Server received EOS_MARKER");
            }
        });
        if (!ws) {
            console.error("Failed to create recording socket");
        } else {
            console.log("Recording socket created and is not null");
        }

        return () => {
            if (ws.readyState === WebSocket.OPEN) ws.close();
        };
    }, [token]);


    const navigateToDashboard = () => {
        navigate("/dashboard");
    }

    const CalculateDimensions = useCallback(() => {
        if (!containerRef.current) return {width: '100%', height: '100%'};
        const containerHeight = containerRef.current.offsetHeight;
        const containerWidth = containerRef.current.offsetWidth;
        const ratio = AspectRatios[aspectRatio];
        let height = containerHeight;
        let width = (height * ratio.width) / ratio.height;
        if (width > containerWidth) {
            width = containerWidth;
            height = (width * ratio.height) / ratio.width;
        }
        return {width: `${width}px`, height: `${height}px`};
    }, [aspectRatio]);

    useEffect(() => {
        const updateDimensions = () => {
            const newDimensions = CalculateDimensions();
            setDimensions(newDimensions);
            AdjustWebcamPosition({newDimensions, webcamPosition, setWebcamPosition});
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, [aspectRatio, CalculateDimensions]);

    useEffect(() => {
        const getDevices = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            setWebcamDevices(devices.filter(device => device.kind === 'videoinput'));
            setMicDevices(devices.filter(device => device.kind === 'audioinput'));
        };
        getDevices();
    }, []);

    const handleMouseDown = (e) => {
        setIsMovingWebcam(true);
    };

    const handleMouseMove = (e) => {
        if (isMovingWebcam && videoContainerRef.current) {
            const containerRect = videoContainerRef.current.getBoundingClientRect();
            const newX = e.clientX - containerRect.left - webcamSize / 2;
            const newY = e.clientY - containerRect.top - webcamSize / 2;
            setWebcamPosition({
                x: Math.max(0, Math.min(newX, containerRect.width - webcamSize)),
                y: Math.max(0, Math.min(newY, containerRect.height - webcamSize)),
            });
        }
    };

    const handleMouseUp = () => {
        setIsMovingWebcam(false);
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isMovingWebcam]);

    const pageStyle = {
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f3f4f5",
    }

    const videoAndControlsAreaStyle = {
        display: "flex",
        flexDirection: "column",
        width: "calc(100vw - 130px)",
        height: "calc(100vh - 40px)",
        // border: "1px solid black",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "70px",
        marginRight: "70px",
        marginTop: "20px",
        marginBottom: "20px",
    }

    const videoAreaStyle = {
        width: '100%',
        flex: 1,
        backgroundColor: 'transparent',
        borderRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const controlBarStyle = {
        height: "93px",
        width: "auto",
        paddingLeft: "22px",
        paddingRight: "22px",
        marginBottom: "5px",
        gap: "12px",
        alignItems: "center",
        display: "inline-flex",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        boxShadow: "0px 20px 75px 0px rgba(0, 0, 0, 0.05)",
    }

    const getVideoStyle = (displayMode) => {
        const baseStyle = {
            width: '100%',
            height: '100%',
        };

        if (displayMode === 'fit') {
            return {
                ...baseStyle,
                objectFit: 'contain',
            };
        } else {
            return {
                ...baseStyle,
                objectFit: 'cover',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            };
        }
    };

    useEffect(() => {
        if (screenStream && screenVideoRef.current) {
            screenVideoRef.current.srcObject = screenStream;
        }
        if (webcamStream && webcamVideoRef.current) {
            webcamVideoRef.current.srcObject = webcamStream;
        }
    }, [screenStream, webcamStream]);

    const recordingOptions = {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 12000000,
        mimeType: "video/x-matroska; codecs=avc1.64002A",
    };


    const StartRecording = () => {
        if (isRecording) {
            console.log("Start recording called but already recording");
            return;
        }

        if (!canvasStream) {
            console.log("Canvas stream not ready");
            return;
        }

        console.log("Start recording called");

        const mixedDestination = mixedDestinationRef.current;
        const audioStreamTrack = mixedDestination.stream.getAudioTracks()[0];

        // Combine canvas stream with audio
        console.log("Canvas stream:", canvasStream);
        console.log("Video tracks:", canvasStream.getVideoTracks());

        console.log("Audio stream track:", audioStreamTrack);


        const canvasStreamTrack = canvasStream.getVideoTracks()[0];

        const mediaStream = new MediaStream([
            canvasStreamTrack,
            audioStreamTrack,
        ]);

        const mediaRecorder = new MediaRecorder(mediaStream, recordingOptions);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = (event) => {
            videoDataRef.current.push(event.data);
            console.log("Data of size ", event.data.size, " pushed to videoDataRef");
            ConsumeData(videoDataRef.current, false, recordingSocket);
        };

        mediaRecorder.start(MEDIA_RECORDER_TIME_SLICE_MS);

        requestDataIntervalRef.current = setInterval(() => {
            mediaRecorder.requestData();
        }, MEDIA_RECORDER_TIME_SLICE_MS);

        setIsRecording(true);
    };

    const StopRecording = () => {
        if (!isRecording) {
            console.log("Stop recording called but not recording");
            return;
        }

        console.log("Stop recording called");

        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }

        clearInterval(requestDataIntervalRef.current);

        setIsRecording(false);
        setRecordingStopped(true);
        console.log("Local recording stopped");
        setTimeout(() => {
            ConsumeData(videoDataRef.current, true, recordingSocket).then(() => {
                ReliablySendEosMarker(recordingSocket, serverReceivedEos, setRecordingDone);
            });
        }, 2000);
    };

    const SendEosMarker = (recordingSocket, serverReceivedEos, setRecordingDone) => {
        if (serverReceivedEos.current) {
            setRecordingDone(true);
            return;
        }
        if (recordingSocket.readyState === WebSocket.OPEN) {
            recordingSocket.send(EOS_MARKER);
        }
    }

    const ReliablySendEosMarker = (recordingSocket, serverReceivedEos, setRecordingDone) => {
        SendEosMarker(recordingSocket, serverReceivedEos);
        // Retry sending EOS_MARKER every 2 seconds three times
        setTimeout(() => SendEosMarker(recordingSocket, serverReceivedEos, setRecordingDone), 1000);
        setTimeout(() => SendEosMarker(recordingSocket, serverReceivedEos, setRecordingDone), 1500);
        setTimeout(() => SendEosMarker(recordingSocket, serverReceivedEos, setRecordingDone), 2500);
        // If server still hasn't received EOS_MARKER, set recording done
        setTimeout(() => {
            setRecordingDone(true);
        }, 3000);
    }

    const theme = useTheme();

    const isXl = useMediaQuery(theme.breakpoints.up('xl'));
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));

    const buttonHeight = isXl ? "40px" : (isLg ? "36px" : "34px");

    const smallButtonStyle = (disabled) => {
        return ({
            display: 'flex',
            width: buttonHeight,
            height: buttonHeight,
            padding: "10px",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            top: "20px",
            right: "32px",
            position: "absolute",
            cursor: 'pointer',
            borderRadius: "8px",
            border: "1px solid #D5D5D5",
            transition: 'background-color 0.3s',
            backgroundColor: disabled ? "linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), #E3E5E8" : "#f3f4f5",
            '&:hover': {
                backgroundColor: disabled ? "linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), #E3E5E8" : "#e5e5e5",
            }
        })
    }


    const handleScreenShareClick = () => {
        if (screenStream) {
            StopCapture({
                streamType: 'screen',
                webcamStream,
                micStream,
                screenStream,
                setWebcamStream,
                setMicStream,
                setScreenStream
            });
        } else {
            StartScreenCapture({setScreenStream});
        }
    }

    const [settingsOpen, setSettingsOpen] = useState(false)

    const messageStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        color: '#666',
        fontSize: '18px',
        textAlign: 'center',
        padding: '20px',
    }

    const settingsButtonDisabled = isRecording;

    const handleNavigateToAsset = () => {
        navigate("/dashboard/asset");
    }

    return (
        <Grid container justifyContent="center" alignItems="center" style={pageStyle}>
            <Box style={{top: "20px", left: "32px", position: "absolute"}}>
                <StudioBackIconButton handleClick={navigateToDashboard} theme={LibrettoStudioTheme.Light}/>
            </Box>
            <Box sx={smallButtonStyle(settingsButtonDisabled)} onClick={() => setSettingsOpen(!settingsOpen)}>
                <SettingsIcon/>
            </Box>
            {recordingStopped ? <NewRecordingStoppedCard showRecordingEndedCard={showRecordingEndedCard}
                                                         participantsLocalRecordingDone={true}
                                                         setShowRecordingEndedCard={setShowRecordingEndedCard}
                                                         onNavigate={handleNavigateToAsset}
                                                         soundboardRecordingDone={true}
                                                         localRecordingDone={recordingDone}/> : null}
            {settingsOpen &&
                <ScreenRecordingSettingsCard onClose={() => setSettingsOpen(false)} backgroundColor={backgroundColor}
                                             setBackgroundColor={setBackgroundColor} aspectRatio={aspectRatio}
                                             setAspectRatio={setAspectRatio} displayMode={displayMode}
                                             setDisplayMode={setDisplayMode} setWebcamSize={setWebcamSize}
                                             webcamSize={webcamSize}/>}
            <Box style={videoAndControlsAreaStyle}>
                <Box
                    ref={containerRef}
                    style={videoAreaStyle}
                >
                    <Box ref={videoContainerRef} style={{
                        ...dimensions,
                        position: 'relative',
                        overflow: 'hidden',
                        border: "2px dashed #e8e8e8",
                        backgroundColor: backgroundColor,
                        borderRadius: '8px',
                    }}>
                        {!screenStream && !webcamStream && (
                            <Box style={messageStyle}>
                                Share screen or turn on camera to start
                            </Box>
                        )}
                        {screenStream && (
                            <video
                                ref={screenVideoRef}
                                style={getVideoStyle(displayMode)}
                                autoPlay
                                playsInline
                            />
                        )}
                        {isWebcamEnabled && webcamStream && (
                            <Box
                                style={{
                                    position: 'absolute',
                                    left: `${webcamPosition.x}px`,
                                    top: `${webcamPosition.y}px`,
                                    width: `${webcamSize}px`,
                                    height: `${webcamSize}px`,
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    border: '3px solid white',
                                    cursor: isMovingWebcam ? 'grabbing' : 'grab',
                                }}
                                onMouseDown={handleMouseDown}
                            >
                                <video
                                    ref={webcamVideoRef}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    autoPlay
                                    playsInline
                                    muted
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Grid container style={controlBarStyle} spacing={1} justifyContent={"center"} marginTop={2}>
                    <Grid item>
                        <AudioInputSelector enabled={isMicEnabled} options={micDevices.map(device => ({
                            value: device.deviceId,
                            label: device.label || `Mic ${device.deviceId.slice(0, 5)}`
                        }))} setEnabled={(val) => StartMicrophone({
                            deviceId: val ? "" : "disabled",
                            setIsMicEnabled,
                            setMicStream,
                            micStream
                        })} onDeviceChange={(deviceId) => StartMicrophone({
                            deviceId: deviceId,
                            setIsMicEnabled,
                            setMicStream,
                            micStream
                        })}/>
                    </Grid>
                    <Grid item>
                        <VideoInputSelector setEnabled={(val) => StartWebcam({
                            deviceId: val ? "" : "disabled",
                            setIsWebcamEnabled,
                            setWebcamStream,
                            webcamStream
                        })} onDeviceChange={(deviceId) => StartWebcam({
                            deviceId: deviceId,
                            setIsWebcamEnabled,
                            setWebcamStream,
                            webcamStream
                        })} enabled={isWebcamEnabled} options={
                            webcamDevices.map(device => ({
                                value: device.deviceId,
                                label: device.label || `Webcam ${device.deviceId.slice(0, 5)}`
                            }))}/>
                    </Grid>
                    <Grid item>
                        <ScreenShareButton enabled={screenStream} handleClick={handleScreenShareClick}/>
                    </Grid>
                    <Grid item>
                        {screenStream || webcamStream && canvasStream ?
                            <ScreenRecordButton recordingInProgress={isRecording} startRecording={StartRecording}
                                                stopRecording={StopRecording}/> : null}
                    </Grid>
                </Grid>
                <div style={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '10px'
                }}>
                </div>
            </Box>
        </Grid>
    )
}

export default ScreenRecord;