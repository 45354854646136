import React, {useContext, useEffect, useState} from 'react';
import {Grid, Card, CardContent, Typography, Slider, Button, CardActions} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import {RefreshTokenAndRetry, UploadToLibretto} from "../../utils/utils";
import {useTheme} from "@mui/material/styles";
import {LocalAudioTrack, Room} from "livekit-client";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import Tooltip from "@mui/material/Tooltip";
import {FetchContext} from "../../context/FetchContext";
import {AuthContext} from "../../context/AuthContext";
import PerformLocalRecording from "../../components/LocalRecording";
import RecordButton from "../Studio/RecordButton";
import {NewCustomAudioVisualizer} from "./NewCustomAudioVisualizer";
import NewRecordButton from "./NewRecordButton";
import Box from "@mui/material/Box";
import HighQualityRecording from "../../components/HighQualityRecording";

const serverUrl = 'wss://librettobeta-htow58t4.livekit.cloud';

const kMaxSoundboardSounds = 4;

const formatToShortTitle = (title) => {
    // Format the title to be short enough to take 140px width max
    if (title.length >= 17) {
        return title.substring(0, 12) + '...';
    }
    return title;
}

export default function NewSoundboardWithButton({token, localRecordingEnabled, projectId, recordingPerformed, roomName, localRecordingInProgress, setLocalRecordingDone, recordingInProgress, startRecording, stopRecording, highQualityRecordingRef}) {

    const fetchContext = useContext(FetchContext);
    const authContext = useContext(AuthContext);

    const [soundFiles, setSoundFiles] = useState(new Map());
    const [soundFilesArray, setSoundFilesArray] = useState([]); // Derived state for array representation
    const [selectedCards, setSelectedCards] = useState(new Map());
    const [audioRefs, setAudioRefs] = useState(new Map());
    const [gainNodes, setGainNodes] = useState(new Map());
    const [hoveredCard, setHoveredCard] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    const [mixedDestination, setMixedDestination] = useState(null);
    const [room, setRoom] = useState(null);

    const setLocalRecordingStopped = () => {
        console.log("Soundboard recording stopped called.")
    }

    // PerformLocalRecording({room, localRecordingInProgress, setLocalRecordingDone, setLocalRecordingStopped, livekitToken: token});

    const UploadFile = (title, url, assetId) => {
        const newSoundFile = {
            // Use randomly created, increasing id
            id: assetId,
            assetId: assetId,
            name: title,
            volume: 50,
            loop: false,
            url: url,
        };
        setSoundFiles(prev => new Map(prev).set(newSoundFile.id, newSoundFile));
        setupAudioElement(newSoundFile).then(({id, audio, gainNode}) => {
            setAudioRefs(prev => new Map(prev).set(id, audio));
            setGainNodes(prev => new Map(prev).set(id, gainNode));
        }).catch(error => console.error("Error setting up new audio file:", error));
    }

    useEffect(() => {
        if (!mixedDestination) return;

        const getSounds = async () => {
            try {
                const response = await fetchContext.authAxios.get(`/soundboard/${projectId}`, {
                    headers: {
                        Authorization: `Bearer ${authContext.getToken()}`,
                    },
                });
                return response.data.sounds;
            } catch (error) {
                console.error("Error fetching sounds:", error);
                return []; // Return an empty array to gracefully handle errors
            }
        };

        getSounds().then((sounds) => {
            // Map each sound to a fetch promise
            const fetchPromises = sounds.map(sound =>
                fetch(sound.url)
                    .then(response => response.blob())
                    .then(blob => UploadFile(sound.title, URL.createObjectURL(blob), sound.assetId))
            );

            Promise.all(fetchPromises)
                .then(() => {
                    console.log("All files fetched and uploaded successfully.");
                })
                .catch(error => {
                    console.error("An error occurred during file upload:", error);
                });
        });

    }, [mixedDestination, fetchContext.authAxios, authContext]);

    // Derive soundFilesArray from soundFiles whenever it updates
    useEffect(() => {
        setSoundFilesArray(Array.from(soundFiles.values()));
    }, [soundFiles]);

    useEffect(() => {
        setAudioContext(new AudioContext());
    }, []);

    useEffect(() => {
        // Initialize mixedDestination once
        if (!audioContext || mixedDestination) return;

        const newMixedDestination = audioContext.createMediaStreamDestination();
        setMixedDestination(newMixedDestination);

        const connectAndPublish = async () => {
            try {
                // Initialize and connect the room
                const room = new Room();
                await room.connect(serverUrl, token);
                console.log("Soundboard is connected to room");

                const initialAudioTrack = new LocalAudioTrack(newMixedDestination.stream.getAudioTracks()[0]);
                await room.localParticipant.publishTrack(initialAudioTrack, {name: "Soundboard track", red: true});
                setRoom(room);

                // Handle room disconnection
                room.on('disconnected', () => {
                    console.log("Soundboard is disconnected from room");
                });
            } catch (error) {
                console.error('Failed to connect to room or publish track:', error);
            }
        };

        connectAndPublish();
    }, [token, audioContext, mixedDestination]);

    useEffect(() => {
        if (soundFilesArray.length === 0) return;

        const handleKeyPress = (event) => {
            const keyIndex = parseInt(event.key, 10) - 1; // Convert "1" to 0, "2" to 1, etc.
            if (keyIndex >= 0 && keyIndex <= 8 && keyIndex < soundFilesArray.length) { // Adjust upper limit based on your number of cards
                togglePlayPause(soundFilesArray[keyIndex].id);
            }
        };

        // Add event listener
        document.addEventListener('keydown', handleKeyPress);

        // Cleanup
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [soundFilesArray, audioRefs, selectedCards]);

    const setupAudioElement = (soundFile) => {
        return new Promise((resolve, reject) => {
            const audio = new Audio();
            audio.src = soundFile.url;
            audio.volume = soundFile.volume / 100;
            audio.loop = soundFile.loop;
            const gainNode = audioContext.createGain();
            gainNode.gain.value = soundFile.volume / 100;

            audio.onended = () => {
                setSelectedCards(prev => {
                    const newMap = new Map(prev);
                    newMap.delete(soundFile.id);
                    return newMap;
                });
                const track = audio.captureStream().getAudioTracks()[0];
                const sourceNode = audioContext.createMediaStreamSource(new MediaStream([track]));
                sourceNode.connect(gainNode);
                gainNode.connect(mixedDestination);
            }

            audio.addEventListener('canplay', () => {
                const track = audio.captureStream().getAudioTracks()[0];
                const sourceNode = audioContext.createMediaStreamSource(new MediaStream([track]));
                sourceNode.connect(gainNode);
                gainNode.connect(mixedDestination); // Connect to AudioContext destination
                console.log("Connecting audio on can-play")
                resolve({id: soundFile.id, audio, gainNode});
            }, {once: true});
            audio.addEventListener('error', () => reject(new Error('Failed to load audio')), {once: true});
            audio.load();
        });
    };

    const theme = useTheme();

    const handleUpload = async (event) => {
        // Log mime type
        const mimeType = event.target.files[0].type;
        if (!mimeType.startsWith('audio')) {
            console.error("Invalid file type. Please upload an audio file.");
            return;
        }
        if (soundFiles.size >= kMaxSoundboardSounds) return;
        const file = event.target.files[0];

        // Prevent upload if file is too large. Greater than 50MB
        if (file.size > 50000000) {
            return;
        }

        if (file) {
            let assetId;
            try {
                assetId = await UploadToLibretto({inputRef: event.target, setProgress: null, filetype: "SoundboardAudio", projectId: projectId, editId: "", fetchContext, authContext});
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    await RefreshTokenAndRetry(e, authContext, fetchContext);
                }
            }
            const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
            UploadFile(fileNameWithoutExtension, URL.createObjectURL(file), assetId);
        }
    };

    const DeleteAsset = async ({assetId}) => {
        await fetchContext.authAxios.delete(`/assets/${assetId}`, {
            headers: {
                Authorization: `Bearer ${authContext.getToken()}`,
            }
        });
    };

    const handleDelete = (id, assetId) => {
        if (assetId) {
            DeleteAsset({assetId});
        }

        setSoundFiles(prev => {
            const newMap = new Map(prev);

            const soundFile = newMap.get(id);
            if (soundFile) {
                URL.revokeObjectURL(soundFile.url);
                newMap.delete(id);
            }

            return newMap;
        });

        setAudioRefs(prev => {
            const newMap = new Map(prev);
            const audio = newMap.get(id);
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
                audio.src = '';
                audio.load(); // Optional if you want to ensure the audio is fully reset
                newMap.delete(id);
            }
            return newMap;
        });

        setGainNodes(prev => {
            const newMap = new Map(prev);
            const gainNode = newMap.get(id);
            if (gainNode) {
                gainNode.disconnect();
                newMap.delete(id);
            }
            return newMap;
        });

        // Similarly, remove from selectedCards if it's tracked by id
        setSelectedCards(prev => {
            const newMap = new Map(prev);
            newMap.delete(id);
            return newMap;
        });
    };

    const togglePlayPause = (id) => {
        const isSelected = selectedCards.has(id);
        if (isSelected) {
            audioRefs.get(id).pause();
            audioRefs.get(id).currentTime = 0;
            setSelectedCards(prev => {
                const newMap = new Map(prev);
                newMap.delete(id);
                return newMap;
            });
        } else {
            audioRefs.get(id).play();
            setSelectedCards(prev => new Map(prev).set(id, true));
        }
    }

    const handleCardClick = (id, event) => {
        if (event.target.closest('.MuiSlider-root')) {
            return;
        }
        togglePlayPause(id);
    };

    const handleVolumeChange = (id, newValue, event) => {
        event.stopPropagation();
        event.preventDefault();

        // Update the sound file's volume in the map
        setSoundFiles(prev => {
            const newMap = new Map(prev);
            const soundFile = {...newMap.get(id), volume: newValue};
            newMap.set(id, soundFile);
            return newMap;
        });

        // Update the GainNode's volume
        const gainNode = gainNodes.get(id);
        if (gainNode) {
            gainNode.gain.value = newValue / 100;
        }

        const audioRef = audioRefs.get(id);
        if (audioRef) {
            audioRef.volume = newValue / 100; // Now the audio element has the updated volume
        }
    };

    const handleLoopChange = (id, event) => {
        event.stopPropagation();
        event.preventDefault();

        // Toggle the sound file's loop property in the map
        setSoundFiles(prev => {
            const newMap = new Map(prev);
            const soundFile = newMap.get(id);
            const updatedSoundFile = {...soundFile, loop: !soundFile.loop};
            newMap.set(id, updatedSoundFile);
            return newMap;
        });

        // Update the audio element's loop property
        const audioRef = audioRefs.get(id);
        if (audioRef) {
            audioRef.loop = !audioRef.loop;
            setAudioRefs(prev => new Map(prev));
        }
    };

    const addSoundHidden = soundFilesArray.length >= kMaxSoundboardSounds ? true : false;

    const soundboardTitleStyle = {
        fontFamily: "Inter",
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: 2, /* 133.333% */
        letterSpacing: 0.48,
        marginBottom: "0.2px",
    }

    return (
        <>
            <HighQualityRecording room={room} localRecordingEnabled={localRecordingEnabled} roomName={roomName} livekitToken={token} setRecordingDone={setLocalRecordingDone} componentName={"Soundboard"} setLocalRecordingStopped={setLocalRecordingStopped} ref={highQualityRecordingRef} />
            <Grid container spacing={1.5} direction="row" alignSelf="center" justifySelf="center">
            <Grid item>
                <NewRecordButton recordingInProgress={recordingInProgress} startRecording={startRecording} stopRecording={stopRecording} recordingPerformed={recordingPerformed} localRecordingInProgress={localRecordingInProgress}/>
            </Grid>
            <Grid item>
                {mixedDestination ? <NewCustomAudioVisualizer audioDestination={mixedDestination}/> :
                    <Typography>Loading soundboard visualizer</Typography>}
            </Grid>
            {soundFilesArray.map((soundFile, index) => (
                <Grid item key={index}>
                    <Card
                        onClick={(event) => handleCardClick(soundFile.id, event)}
                        onMouseEnter={() => setHoveredCard(index)}
                        onMouseLeave={() => setHoveredCard(null)}
                        elevation={5}
                        style={{
                            cursor: 'pointer',
                            position: 'relative',
                            border: selectedCards.has(soundFile.id) ? `1px solid ${theme.palette.primary.main}` : 'none',
                            overflow: 'visible',
                            height: 52,
                            width: 155,
                            maxWidth: 155
                        }}
                    >
                        <Tooltip>
                            <CardContent style={{
                                padding: '4px',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <Box style={soundboardTitleStyle}>
                                    {formatToShortTitle(soundFile.name)}
                                </Box>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <Tooltip title="Play on loop">
                                        <IconButton
                                            style={{
                                                padding: '2px',
                                                border: soundFile.loop ? '2px solid #3f51b5' : 'none',
                                            }}
                                            size="small"
                                            onClick={(event) => handleLoopChange(soundFile.id, event)}
                                        >
                                            <AllInclusiveIcon style={{ fontSize: "0.8rem" }}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Slider
                                        value={soundFile.volume}
                                        size="small"
                                        onChange={(event, newValue) => handleVolumeChange(soundFile.id, newValue, event)}
                                        aria-labelledby="continuous-slider"
                                        sx={{color: "#1a1a1a", height: "2px", width: '90px'}}
                                    />
                                </div>
                            </CardContent>
                        </Tooltip>
                        <CardActions style={{
                            display: hoveredCard === index ? 'block' : 'none',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: 0,
                            margin: '0',
                            zIndex: 1000,
                        }}>
                            <IconButton
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleDelete(soundFile.id, soundFile.assetId);
                                }}
                                size="small"
                                style={{padding: '2px'}}
                            >
                                <Close style={{fontSize: "0.8rem"}}/>
                            </IconButton>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
            <Grid item hidden={addSoundHidden}>
                <Card variant="outlined" style={{borderStyle: 'solid', height: "52px", borderRadius: "8px",}}>
                    <CardContent style={{textAlign: 'center', alignSelf: "center", padding: "4px"}}>
                        <Tooltip title="Add sound">
                            <Button component="label">
                                <AddIcon fontSize="medium" sx={{color: "#1a1a1a"}}/>
                                <input
                                    type="file"
                                    hidden
                                    accept="audio/*"
                                    onChange={handleUpload}
                                />
                            </Button>
                        </Tooltip>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        </>
    );
}
