import React, {createContext, useContext, useEffect, useState} from 'react';
import {getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, setPersistence, browserSessionPersistence, onIdTokenChanged, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import {FetchContext} from "./FetchContext";
import {LibrettoPlan} from "../utils/utils";

const AuthContext = createContext();
const { Provider } = AuthContext;

const kIsAuthenticated = 'isAuthenticatedOnLibretto';
const kLibrettoToken = 'librettoUserToken';
const kLibrettoUserId = 'librettoUserId';
const kLibrettoDisplayName = 'librettoDisplayName';
const kLibrettoEmail = 'librettoEmail';
const kLibrettoPhotoUrl = 'librettoPhotoUrl';
const kLibrettoLiveblocksToken = 'librettoLiveblocksToken';
const kLibrettoCurrentProjectId = 'librettoCurrentProjectId';
const kLibrettoUserPlan = 'librettoUserPlan';
const kUserEmailVerified = 'librettoUserEmailVerified';

const AuthProvider = ({ children }) => {
    const firebaseAuth = getAuth();

    const isAuthenticated = sessionStorage.getItem(kIsAuthenticated);
    const isEmailVerified = sessionStorage.getItem(kUserEmailVerified);
    const token = sessionStorage.getItem(kLibrettoToken);
    const userId = sessionStorage.getItem(kLibrettoUserId);

    const [authState, setAuthState] = useState({
        isAuthenticated: isAuthenticated,
        emailVerified: isEmailVerified,
        token: token,
        userId: userId,
        displayName: null,
        email: null,
        userPlan: null,
        photoUrl: null,
    });

    const getLibrettoLiveblocksToken = () => {
        return sessionStorage.getItem(kLibrettoLiveblocksToken);
    };

    const getLibrettoCurrentProjectId = () => {
        return sessionStorage.getItem(kLibrettoCurrentProjectId);
    }

    const setAuthInfo = ({ isAuthenticated, emailVerified, token, userId, displayName, email, photoUrl, userPlan }) => {
        sessionStorage.setItem(kIsAuthenticated, isAuthenticated);
        sessionStorage.setItem(kUserEmailVerified, emailVerified);
        sessionStorage.setItem(kLibrettoToken, token);
        sessionStorage.setItem(kLibrettoUserId, userId);
        sessionStorage.setItem(kLibrettoDisplayName, displayName);
        sessionStorage.setItem(kLibrettoEmail, email);
        sessionStorage.setItem(kLibrettoPhotoUrl, photoUrl);
        sessionStorage.setItem(kLibrettoUserPlan, userPlan);
        setAuthState({
            isAuthenticated: isAuthenticated,
            emailVerified: emailVerified,
            token: token,
            userId: userId,
            displayName: displayName,
            email: email,
            photoUrl: photoUrl,
            userPlan: userPlan,
        });
    };

    const logout = async () => {
        sessionStorage.removeItem(kIsAuthenticated);
        sessionStorage.removeItem(kUserEmailVerified);
        sessionStorage.removeItem(kLibrettoToken);
        sessionStorage.removeItem(kLibrettoUserId);
        sessionStorage.removeItem(kLibrettoDisplayName);
        sessionStorage.removeItem(kLibrettoEmail);
        sessionStorage.removeItem(kLibrettoPhotoUrl);
        sessionStorage.removeItem(kLibrettoUserPlan);
        setAuthState({isAuthenticated: 'false', emailVerified: 'false', token: null, userId: null, displayName: null, email: null, photoUrl: null, userPlan: null});
        await signOut(firebaseAuth);
    };

    const isUserAuthenticated = () => {
        return sessionStorage.getItem(kIsAuthenticated) === 'true' && sessionStorage.getItem(kUserEmailVerified) === 'true';
    };

    const getUserDisplayName = () => {
        return sessionStorage.getItem(kLibrettoDisplayName);
    }

    const getUserPlan = () => {
        return sessionStorage.getItem(kLibrettoUserPlan);
    }

    const getUserEmail = () => {
        return sessionStorage.getItem(kLibrettoEmail);
    }

    const getUserPhotoUrl = () => {
        return sessionStorage.getItem(kLibrettoPhotoUrl);
    }

    const getToken = () => {
        return sessionStorage.getItem(kLibrettoToken);
    }

    const getUserId = () => {
        return sessionStorage.getItem(kLibrettoUserId);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
            if (user) {
                user.getIdToken().then((idToken) => {
                    setAuthInfo({
                        isAuthenticated: true,
                        token: idToken,
                        userId: user.uid,
                        email: user.email,
                        displayName: user.displayName,
                        photoUrl: user.photoURL,
                        emailVerified: user.emailVerified,
                    });
                });
            } else {
                setAuthInfo({
                    isAuthenticated: false,
                    emailVerified: false,
                    token: null,
                    userId: null,
                    displayName: null,
                    email: null,
                    photoUrl: null,
                });
            }
        });


        const unsubscribeToken = onIdTokenChanged(firebaseAuth, async (user) => {
            if (user) {
                const idToken = await user.getIdToken();
                setAuthState(prevState => ({
                    ...prevState,
                    token: idToken
                }));
            }
        });


        return () => {
            unsubscribe();
            unsubscribeToken();
        }
    }, []);


    // Login and Signup application level
    const [loginFormOpen, setLoginFormOpen] = React.useState(false);
    const [signupFormOpen, setSignupFormOpen] = React.useState(false);
    const [selectedLibrettoPlanForSignup, setSelectedLibrettoPlanForSignup] = React.useState(LibrettoPlan.Starter);
    const [signupCode, setSignupCode] = React.useState("");
    const [signupCodeTwo, setSignupCodeTwo] = React.useState("");
    const [signUpStateMessage, setSignUpStateMessage] = React.useState({forSignup: false, forSignIn: false, message: "", user: null});
    const [signUpStateMessageOpen, setSignUpStateMessageOpen] = React.useState(false);

    const provider = new GoogleAuthProvider();

    const fetchContext = useContext(FetchContext);

    const signup = async ({token, displayName, photoUrl, email, user}) => {
        try {
            const effectivePlan = selectedLibrettoPlanForSignup ? selectedLibrettoPlanForSignup : LibrettoPlan.Starter;

            const response = await fetchContext.authAxios.post('/signup', {
                displayName: displayName,
                plan: effectivePlan,
                code: signupCode,
                codeTwo: signupCodeTwo,
                email: email,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            if (response.status === 200) {
                handleSignupFormClose();
                await login({token: token, email: email, photoUrl: photoUrl, user: user});
                return;
            }

            if (response.status === 202) {
                const message = "An email has been sent to you. Please verify your email address before logging in!";
                setSignUpStateMessage({forSignup: true, forSignIn: false, message: message, user: null});
                setSignUpStateMessageOpen(true);
            }
        } catch (error) {
                if (error.response && (error.response.status === 500)) {
                    // Check if pricingOption has a prefix "Welcome"
                    const isWelcomePlan = selectedLibrettoPlanForSignup === LibrettoPlan.AppSumo;

                    const message = isWelcomePlan ? "Error. Make sure you have entered a valid code and try again!" : "Internal server error. Please try again later!";

                    setSignUpStateMessage({forSignup: true, forSignIn: false, message: message, user: null});
                    setSignUpStateMessageOpen(true);
                }
        }
    };

    const login = async ({token, email, photoUrl, user}) => {
        try {
            const response = await fetchContext.authAxios.get('/login',
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });

            if (response.status === 200) {
                setAuthInfo({isAuthenticated: "true", emailVerified: "true", userPlan: response.data.userPlan, token: token, userId: firebaseAuth.currentUser.uid, email: email, displayName: response.data.displayName, photoUrl: photoUrl});
            }
        } catch (error) {
            if (error.response && (error.response.status === 401)) {
                setSignUpStateMessage({forSignup: false, forSignIn: true, message: "Invalid credentials. Please make sure you have created an account and entered valid credentials!", user: null});
                setSignUpStateMessageOpen(true);
            }
            if (error.response && (error.response.status === 403)) {
                setSignUpStateMessage({forSignup: false, forSignIn: true, message: "The email address is not verified. Please verify your email by clicking the link we sent!", user: user});
                setSignUpStateMessageOpen(true);
            }
            if (error.response && (error.response.status === 500)) {
                setSignUpStateMessage({forSignup: false, forSignIn: true, message: "Internal server error. Please try again later!", user: null});
                setSignUpStateMessageOpen(true);
            }
            if (error.response && (error.response.status === 451)) {
                setSignUpStateMessage({forSignup: false, forSignIn: true, message: "Unable to login. Please email contact@libretto.fm if this persists.", user: null});
                setSignUpStateMessageOpen(true);
            }
        }
    }

    const refreshToken = async () => {
        try {
            const idToken = await firebaseAuth.currentUser.getIdToken(/* forceRefresh */ true);
            sessionStorage.setItem(kLibrettoToken, idToken);
            return idToken;
        } catch (error) {
            console.error("Error refreshing ID token:", error);
            return null;
        }
    }


    const signinWithGoogle = async () => {
        try {
            await setPersistence(firebaseAuth, browserSessionPersistence);
            const result = await signInWithPopup(firebaseAuth, provider);
            const photoUrl = result.user.photoURL;
            const email = result.user.email;

            try {
                const idToken = await firebaseAuth.currentUser.getIdToken(/* forceRefresh */ true);
                await login({token: idToken, email: email, photoUrl: photoUrl, user: result.user});
                handleLoginFormClose();
            } catch (error) {
                // Handle errors from getIdToken or isLoginAllowed
                console.error("Error in ID token retrieval or login check:", error);
            }
        } catch (error) {
            // Handle errors from signInWithPopup
            console.error("Error signing in with Google:", error);
        }
    };

    const signinWithEmail = async ({email, password}) => {
        try {
            await setPersistence(firebaseAuth, browserSessionPersistence);
            await signInWithEmailAndPassword(firebaseAuth, email, password);

            try {
                const idToken = await firebaseAuth.currentUser.getIdToken(/* forceRefresh */ true);
                await login({token: idToken, email: email, photoUrl: "", user: firebaseAuth.currentUser});
                handleLoginFormClose();
            } catch (error) {
                console.error("Error getting ID token:", error);
            }
        } catch (error) {
            if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
                setSignUpStateMessage({forSignup: false, forSignIn: true, message: "Invalid email/password. Make sure you have an account created and enter valid credentials!", user: null});
                setSignUpStateMessageOpen(true);
            }
        }
    }

    const signupWithGoogle = async ({pricingOption}) => {
        try {
            const userCredential = await signInWithPopup(firebaseAuth, provider);
            const displayName = userCredential.user.displayName;
            const photoUrl = userCredential.user.photoURL;
            const email = userCredential.user.email;

            try {
                const idToken = await firebaseAuth.currentUser.getIdToken(/* forceRefresh */ true);
                await signup({token: idToken, displayName: displayName, photoUrl: photoUrl, email: email, user: userCredential.user});
                handleSignupFormClose();
            } catch (error) {
                console.error("Error getting ID token:", error);
            }
        } catch (error) {
            console.error("Error signing in with popup:", error);
        }
    };

    const signUpWithEmailAndPassword = async ({displayName, email, password, pricingOption}) => {

        try {
            const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);

            await sendEmailVerification(userCredential.user);

            try {
                const idToken = await firebaseAuth.currentUser.getIdToken(/* forceRefresh */ true);
                await signup({displayName: displayName, token: idToken, photoUrl: "", email: email, user: userCredential.user});
                handleSignupFormClose();
            } catch (error) {
                console.error("Error getting ID token:", error);
            }
        } catch (error) {
            console.error("Error signing up with email and password:", error);
        }
    };


    const handleLoginFormOpen = () => {
        setLoginFormOpen(true);
    }

    const handleLoginFormClose = () => {
        setLoginFormOpen(false);
    }

    const handleSignupFormOpen = ({librettoPlan, code, codeTwo}) => {
        setSelectedLibrettoPlanForSignup(librettoPlan);
        setSignupCode(code);
        setSignupCodeTwo(codeTwo);
        setSignupFormOpen(true);
    }

    const handleSignupFormClose = () => {
        setSignupFormOpen(false);
    }

    const handleSignupStateMessageClose = () => {
        setSignUpStateMessageOpen(false);
    }


    return (
        <Provider
            value={{
                authState,
                logout,
                isUserAuthenticated,
                getToken,
                refreshToken,
                getLibrettoLiveblocksToken,
                getLibrettoCurrentProjectId,
                getUserId,
                getUserDisplayName,
                getUserPlan,
                getUserEmail,
                getUserPhotoUrl,
                signinWithGoogle,
                signupWithGoogle,
                handleLoginFormOpen,
                handleLoginFormClose,
                handleSignupFormOpen,
                handleSignupFormClose,
                loginFormOpen,
                signupFormOpen,
                signUpStateMessage,
                signUpStateMessageOpen,
                handleSignupStateMessageClose,
                signUpWithEmailAndPassword,
                signinWithEmail,
            }}
        >
            {children}
        </Provider>
    );
};

export { AuthProvider, AuthContext };
