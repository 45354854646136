import React, {useEffect, useState} from 'react';
import { Card, CardContent, CardActions, Button, Typography, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {StopRounded} from "@mui/icons-material";
import {RecordingEndedCard, UploadSuccessfulCard} from "../NewStudio/RecordingEndedCard";


export const NewRecordingStoppedCard = ({ setShowRecordingEndedCard, showRecordingEndedCard, participantsLocalRecordingDone, onNavigate, soundboardRecordingDone, localRecordingDone }) => {

    if (soundboardRecordingDone && localRecordingDone && participantsLocalRecordingDone) {

        return (
            showRecordingEndedCard  && <UploadSuccessfulCard onClose={() => setShowRecordingEndedCard(false)} isHost={true} onNavigate={onNavigate}/>
        )
    }

    return (
        showRecordingEndedCard && <RecordingEndedCard onClose={() => setShowRecordingEndedCard(false)}/>
    )
}

export const NewGuestRecordingStoppedCard = ({ setShowRecordingEndedCard, showRecordingEndedCard, localRecordingDone }) => {

    if (localRecordingDone) {

        return (
            showRecordingEndedCard && <UploadSuccessfulCard onClose={() => setShowRecordingEndedCard(false)} isHost={false}/>
        )
    }

    return (
        showRecordingEndedCard && <RecordingEndedCard onClose={() => setShowRecordingEndedCard(false)}/>
    )
}

export const RecordingStoppedCard = ({ onClose, onNavigate, soundboardRecordingDone, localRecordingDone }) => {

    if (soundboardRecordingDone && localRecordingDone) {

        setTimeout(() => {
            onNavigate();
        }, 5000);

        return (
            <Card
                elevation={5}
                style={{
                    cursor: 'pointer', position: 'absolute',
                    bottom: 25,
                    left: 25,
                    maxWidth: 400,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background
                    borderRadius: '16px', // Rounded corners
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)', overflow: 'visible', minHeight: 160
                }}
            >
                <CardContent>
                    <IconButton onClick={onClose} sx={{position: 'absolute', right: 8, top: 8}} variant="contained">
                        <CloseIcon style={{fontSize: "0.9rem", color: "white"}}/>
                    </IconButton>
                    <Typography variant="subtitle1" marginBottom={1}>
                    <span style={{display: 'flex', alignItems: 'center'}}>
                    <CheckCircleIcon style={{fontSize: "1.5rem", marginRight: 8, color: 'green'}}/>
                    <Typography color="white">Success</Typography>
                    </span>
                    </Typography>
                    <Typography variant="subtitle2" color="white">
                        Local recording has finished uploading and is being processed. Navigating to the asset.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button color="primary" onClick={onNavigate} variant="contained" style={{borderRadius: '20px'}}>
                        <Typography>View tracks</Typography>
                    </Button>
                </CardActions>
            </Card>
        );
    }

    return (
        <Card
            elevation={5}
            style={{
                cursor: 'pointer', position: 'absolute',
                bottom: 25,
                left: 25,
                maxWidth: 400,
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background
                borderRadius: '16px', // Rounded corners
                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)', overflow: 'visible', minHeight: 160
            }}
        >
            <CardContent>
                <IconButton onClick={onClose} sx={{position: 'absolute', right: 8, top: 8}} variant="contained">
                    <CloseIcon style={{fontSize: "0.9rem", color: "white"}}/>
                </IconButton>
                <Typography variant="subtitle1" marginBottom={1}>
                    <span style={{display: 'flex', alignItems: 'center'}}>
                    <StopRounded style={{fontSize: "1.5rem", marginRight: 8, color: 'red'}}/>
                    <Typography color="white">Recording ended.</Typography>
                    </span>
                </Typography>
                <Typography variant="subtitle2" color="white">
                    Please wait for uploading to finish before navigating away from this page.
                </Typography>
            </CardContent>
            <CardActions>
            </CardActions>
        </Card>
    );
};

export const GuestStudioRecordingStoppedCard = ({ onClose, localRecordingDone }) => {

   if (localRecordingDone) {

       return (
           <Card
               elevation={5}
               style={{
                   cursor: 'pointer', position: 'absolute',
                   bottom: 25,
                   left: 25,
                   maxWidth: 400,
                   backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background
                   borderRadius: '16px', // Rounded corners
                   boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)', overflow: 'visible', minHeight: 160
               }}
           >
               <CardContent>
                   <IconButton onClick={onClose} sx={{position: 'absolute', right: 8, top: 8}} variant="contained">
                       <CloseIcon style={{fontSize: "0.9rem", color: "white"}}/>
                   </IconButton>
                   <Typography variant="subtitle1" marginBottom={1}>
                    <span style={{display: 'flex', alignItems: 'center'}}>
                    <CheckCircleIcon style={{fontSize: "1.5rem", marginRight: 8, color: 'green'}}/>
                    <Typography color="white">Success</Typography>
                    </span>
                   </Typography>
                   <Typography variant="subtitle2" color="white">
                       Local recording has finished uploading and is being processed. Feel free to leave the studio.
                   </Typography>
               </CardContent>
           </Card>
       );
   }

    return (
        <Card
            elevation={5}
            style={{
                cursor: 'pointer', position: 'absolute',
                bottom: 25,
                left: 25,
                maxWidth: 400,
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background
                borderRadius: '16px', // Rounded corners
                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)', overflow: 'visible', minHeight: 160
            }}
        >
            <CardContent>
                <IconButton onClick={onClose} sx={{position: 'absolute', right: 8, top: 8}} variant="contained">
                    <CloseIcon style={{fontSize: "0.9rem", color: "white"}}/>
                </IconButton>
                <Typography variant="subtitle1" marginBottom={1}>
                    <span style={{display: 'flex', alignItems: 'center'}}>
                    <StopRounded style={{fontSize: "1.5rem", marginRight: 8, color: 'red'}}/>
                    <Typography color="white">Recording ended.</Typography>
                    </span>
                </Typography>
                <Typography variant="subtitle2" color="white">
                    Please wait for uploading to finish before navigating away from this page.
                </Typography>
            </CardContent>
            <CardActions>
                {/*<Button color="primary" onClick={onNavigate} variant="contained" style={{borderRadius: '20px'}}>*/}
                {/*    <Typography>View tracks</Typography>*/}
                {/*</Button>*/}
            </CardActions>
        </Card>
    );
};
