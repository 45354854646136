import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Link} from "react-router-dom";

const PrivacyText = () => {
    return (
        <Typography>
            Your recordings and uploads to Libretto, and all generated clips remain confidential.
            Read more about our {' '}
            <Link to='/privacy' style={{ textDecoration: 'none', color: 'primary' }}>
                privacy policy
            </Link>
            {' '}and{' '}
            <Link to="/terms" style={{ textDecoration: 'none' }}>
                terms of service
            </Link>
            .
        </Typography>
    )
}

const mock = [
    {
        title: 'Do I need to enter a credit card to try Libretto?',
        subtitle:
            "No, the Starter plan is free and includes everything you need to record, edit and transcribe some duration of audio and video content per month.",
    },
    {
        title: 'Can I upgrade to a Standard or Creator plan after signing up for the Starter plan?',
        subtitle:
            'Yes, you can upgrade from the Settings page after you are logged in to Libretto.',
    },
    {
        title: 'How are subscription payments processed?',
        subtitle:
            'Libretto subscriptions are processed automatically to the credit card you provide, according to your selected billing cycle. Users requiring custom invoicing options (such as ACH transfer) can reach out to contact@libretto.fm to learn more.',
    },
    {
        title: 'Is there a limit on audio transcription?',
        subtitle:
            'Yes, there is a limit on the duration of audio and video content you can transcribe per month. Depending on your plan, you can transcribe up to 30 hours of audio per month.',
    },
    {
        title: 'How does Libretto protect my privacy?',
        subtitle: <PrivacyText/>
    },
    {
        title: 'How do I delete my account?',
        subtitle:
            'You can delete your account and permanently wipe your data from our servers by emailing us at contact@libretto.fm.',
    },
];

const Faq = () => {
    return (
        <Box>
            <Box marginBottom={4}>
                <Typography fontWeight={700} variant={'h4'} align={'center'}>
                    Frequently asked questions
                </Typography>
            </Box>
            <Grid container spacing={4}>
                {mock.map((item, i) => (
                    <Grid key={i} item xs={12} sm={6} md={4}>
                        <Typography variant={'h6'} fontWeight={600} gutterBottom>
                            {item.title}
                        </Typography>
                        <Typography color="text.secondary">{item.subtitle}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Faq;
